.SentEmailForVerification .wrapper{
    display: grid;
    grid-template-columns: 20% 80%;
    height: 100vh;
   
}
.SentEmailForVerification .wrapper .side-1 .overlay{
    background: linear-gradient(
        to bottom,
        #01a94af1 10%,
        var(--primaryColorAcent) 100%
      );
      /* height: 100vh; */
    height: 100%;
}
.SentEmailForVerification .wrapper .side-2{
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.SentEmailForVerification .wrapper .imgCover{
    width: 150px;
    padding-bottom: 1rem;
}

.SentEmailForVerification .wrapper h3{
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 10px;
}
.SentEmailForVerification .wrapper p{
  
    font-size: 1.1rem;
   
}

@media screen and (max-width: 500px) {
    .SentEmailForVerification .wrapper .side-2{
       width: 90%;
       margin: 0 auto;
       text-align: center;
    }
    
    .SentEmailForVerification .wrapper .imgCover{
        width: 70px;
        padding-bottom: 1rem;
    }
    
    .SentEmailForVerification .wrapper h3{
        font-weight: bold;
        font-size: 1rem;
        margin-bottom: 10px;
    }
    .SentEmailForVerification .wrapper p{
      
        font-size: .8rem;
       
    }
}