.fund-main {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 80px;
  /* justify-content: space-between; */
  padding: 20px;
}

.FundIncrement {
  max-width: 1200px;
  margin: 0 auto;
  /* padding: 20px; */
}

.fund-container {
  display: flex;
  flex-direction: column;
}

.FundIncrement .pageTitle {
  text-align: center;
  margin: 20px 0px;
  font-weight: bold;
}

.FundIncrement .pageWrapper {
  display: flex;
  flex-direction: row;
  align-items: start;
  width: 100%;
}

/* Form section */
.FundIncrement form {
  width: 100%;
}

/* Notification section */
.FundIncrement .incrementDisplay {
  position: sticky;
  top: 20px;
  background: #f5f5f5;
  /* padding: 15px; */
  border-radius: 8px;
  max-height: calc(100vh - 40px);
  overflow-y: auto;
}

.FundIncrement .incrementDisplay .statusContainer {
  background: white;
  padding: 8px;
  margin-bottom: 15px;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.FundIncrement .submitButtonContainer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .fund-main,
  .FundIncrement .pageWrapper {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  .FundIncrement .incrementDisplay {
    position: static;
    width: 100%;
    margin-top: 30px;
  }
}

@media screen and (max-width: 560px) {
  .hide_on_small {
    display: none;
  }

  .hide_on_large {
    display: block;
  }

  .FundIncrement {
    /* padding: 10px; */
  }
}
