/* eslint-disable  */
.home_dashboard {
  background-color: white;
  display: flex;
  grid-template-columns: 15% 85%;
  position: relative;
  height: 100vh;
}
.home_dashboard .DashBoardTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.home_dashboard .DashBoardTitle .email_message {
  background: var(--warning);
  color: white;
  padding: 2px 10px;
  border-radius: 0.5rem;
}
.home_dashboard .SideBarMenu {
  background: linear-gradient(to bottom, var(--primaryColor) 20%, var(--primaryColorAcent) 100%);
  height: 100vh;
  overflow: hidden;
  min-width: 15%;
  max-width: 15%;
  width: 15%;
  background-position: left !important;
}
.home_dashboard .SideBarMenu .overlay {
  background: linear-gradient(to bottom, rgba(1, 169, 74, 0.9450980392) 10%, var(--primaryColorAcent) 100%);
  height: 100vh;
}
.home_dashboard .SideBarMenu ul {
  padding-left: 0;
}
.home_dashboard .SideBarMenu ul a {
  list-style: none;
  display: flex;
  align-items: center;
  padding: 0.1rem 0rem;
  margin: 0.5rem 0rem;
  text-decoration: none;
  color: var(--whiteColor);
}
.home_dashboard .SideBarMenu ul a .icon {
  margin-left: 0.4rem;
  padding-right: 0.4rem;
}
.home_dashboard .SideBarMenu ul a:hover {
  background: #008037;
  cursor: pointer;
}
.home_dashboard .MainPage {
  background: #f4f4f4;
  height: 100%;
  flex-grow: 1;
  width: 100%;
  overflow-x: hidden;
}
.home_dashboard .MainPage .MainPageItem {
  height: 90vh;
  overflow-y: auto;
  
}



.SmallNavBar {
  background: var(--whiteColor);
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  padding: 0.4rem 1rem;
}
.SmallNavBar .menuLinks {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.SmallNavBar .menuLinks .title {
  font-weight: bold;
  font-size: 0.9rem;
  margin-right: 1rem;
}
.SmallNavBar .menuLinks a {
  padding: 0rem 0.5rem;
  text-decoration: none;
  font-size: 0.8rem;
  font-weight: 400;
  color: var(--grayColor);
}
.SmallNavBar .userProfile {
  display: flex;
  justify-content: center;
  align-items: center;
}
.SmallNavBar .userProfile div {
  margin-left: 0.5rem;
}

.MainPageSections {
  display: grid;
  grid-template-columns: 75% 25%;
}
.MainPageSections .SectionTwoNotificationSection {
  position: relative;
}
.MainPageSections .SectionTwoNotificationSection .NotificationPanel {
  height: 80vh;
  overflow: scroll;
}
.MainPageSections .SectionTwoNotificationSection .calender-box {
  background: var(--whiteColor);
  margin-bottom: 1rem;
  padding: 0.7rem;
}
.MainPageSections .SectionTwoNotificationSection .calender-box .dateContainer {
  position: relative;
  height: 250px;
  width: 100%;
  overflow: none;
}
.MainPageSections .SectionTwoNotificationSection .notifications {
  background: var(--whiteColor);
  padding: 0.3rem;
}
.MainPageSections .SectionTwoNotificationSection .notifications h3 {
  font-size: 1rem;
  font-weight: bold;
}
.MainPageSections .SectionTwoNotificationSection .notifications .flex {
  display: flex;
  align-items: center;
  padding: 0.5rem 0rem;
}
.MainPageSections .SectionTwoNotificationSection .notifications .flex .icon {
  margin-right: 0.4rem;
}
.MainPageSections .SectionTwoNotificationSection .notifications .flex .notif_title {
  font-size: 0.7rem;
  margin: 0;
  font-weight: bold;
}
.MainPageSections .SectionTwoNotificationSection .notifications .flex .notif_msg {
  font-size: 0.7rem;
  padding: 0.1rem 0rem;
}
.MainPageSections .SectionTwoNotificationSection .notifications .flex .time {
  font-size: 0.6rem;
  color: var(--grayColor);
}
.MainPageSections .SectionTwoNotificationSection .carStatusDisplay {
  background: var(--whiteColor);
  padding: 0.3rem;
}
.MainPageSections .SectionTwoNotificationSection .carStatusDisplay h3 {
  font-size: 1rem;
  font-weight: bold;
}
.MainPageSections .SectionTwoNotificationSection .carStatusDisplay .statusContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.MainPageSections .SectionTwoNotificationSection .carStatusDisplay .flex {
  display: flex;
  align-items: center;
  padding: 0.5rem 0rem;
}
.MainPageSections .SectionTwoNotificationSection .carStatusDisplay .flex .icon {
  margin-right: 0.4rem;
}
.MainPageSections .SectionTwoNotificationSection .carStatusDisplay .flex .notif_title {
  font-size: 0.7rem;
  margin: 0;
  font-weight: bold;
}
.MainPageSections .SectionTwoNotificationSection .carStatusDisplay .flex .notif_msg {
  font-size: 0.7rem;
  padding: 0.1rem 0rem;
}
.MainPageSections .SectionTwoNotificationSection .carStatusDisplay .flex .time {
  font-size: 0.6rem;
  color: var(--grayColor);
}
.MainPageSections .SectionTwoNotificationSection .carStatusDisplay .status {
  border-radius: 4pc;
  padding: 0rem 0.5rem;
  color: var(--whiteColor);
  font-size: 0.8rem;
}
.MainPageSections .SectionOneMainPageDisplay {
  padding-right: 0.9rem;
}
.MainPageSections .SectionOneMainPageDisplay .statisticsDisplay {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 1rem;
}
.MainPageSections .SectionOneMainPageDisplay .statisticsDisplay .box {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.8rem 0.7rem;
  background: var(--whiteColor);
  border-radius: 0rem 1rem 0rem 0rem;
}
.MainPageSections .SectionOneMainPageDisplay .statisticsDisplay .active {
 color: white;
  background: var(--primaryColor);
  border-radius: 0rem 1rem 0rem 0rem;
}
.MainPageSections .SectionOneMainPageDisplay .statisticsDisplay .box .iconContainer {
  padding: 0.8rem;
  margin-right: 0.5rem;
  border-radius: 0.3vw;
}
.MainPageSections .SectionOneMainPageDisplay .statisticsDisplay .box .iconContainer .icon {
  color: var(--whiteColor);
}
.MainPageSections .SectionOneMainPageDisplay .statisticsDisplay .active .iconContainer .icon {
  color: black;
}
.MainPageSections .SectionOneMainPageDisplay .statisticsDisplay .box h3 {
  margin: 0rem;
  padding: 0rem;
}
.MainPageSections .SectionOneMainPageDisplay .activityLinksCards {
  background: var(--whiteColor);
  margin-top: 1rem;
  padding: 1rem 1rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1.4rem;
  justify-content: center;
  align-items: center;
}
.MainPageSections .SectionOneMainPageDisplay .activityLinksCards .box {
  margin: 0rem 0rem;
  padding: .5rem 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: var(--shadow);
  cursor: pointer;
  transition: ease-in-out all .2s;
  transform: scale(1);
}
.MainPageSections .SectionOneMainPageDisplay .activityLinksCards .box:hover {
 
  box-shadow: var(--shadowHover);
  transform: scale(0.9);
  
}
.MainPageSections .SectionOneMainPageDisplay .activityLinksCards .box .imgCover {
  width: 50px;
  height: 50px;
  /* background-color: red !important; */
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.MainPageSections .SectionOneMainPageDisplay .activityLinksCards .box .imgCover img {
  width: 70%;
}
.MainPageSections .SectionOneMainPageDisplay .activityLinksCards .box h2 {
  font-size: .8rem;
  font-weight: bold;
  text-align: center;
  padding-top: 1rem;
  text-transform: uppercase;
}
.MainPageSections .SectionOneMainPageDisplay .activityLinksCards .box p {
  text-align: center;
  color: rgba(1, 169, 74, 0.7725490196);
  text-transform: uppercase;
  font-size: 0.7rem;
}

@media screen and (max-width: 560px) {
  .home_dashboard {
    background-color: white;
    display: flex;
    grid-template-columns: 15% 85%;
    position: relative;
    height: auto;
  }
  .home_dashboard .DashBoardTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .home_dashboard .DashBoardTitle .email_message {
    background: var(--warning);
    color: white;
    padding: 2px 6px;
    font-size: 0.7rem;
    border-radius: 0.5rem;
  }
  .home_dashboard .SideBarMenu {
    display: block;
    position: absolute;
    z-index: 9999;
    background: linear-gradient(to bottom, #01a94b 20%, #015f39 100%);
    height: 100vh;
    overflow: none !important;
    min-width: 50% !important;
    max-width: 50% !important;
    width: 50% !important;
  }
  .home_dashboard .SideBarMenu .title_nd_closeBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .home_dashboard .SideBarMenu ul {
    padding-left: 0;
  }
  .home_dashboard .SideBarMenu ul a {
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0.1rem 0rem;
    margin: 0.5rem 0rem;
    text-decoration: none;
    color: var(--whiteColor);
  }
  .home_dashboard .SideBarMenu ul a .icon {
    margin-left: 0.4rem;
    padding-right: 0.4rem;
  }
  .home_dashboard .SideBarMenu ul a:hover {
    background: #00d15b;
    cursor: pointer;
  }
  .home_dashboard .MainPage {
    background: #f4f4f4;
    height: 100%;
    flex-grow: 1;
  }
  .home_dashboard .MainPage .MainPageItem {
    height: auto;
  }
  .MainPageSections {
    display: block;
  }
  .SmallNavBar {
    background: var(--whiteColor);
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-between;
    padding: 0.4rem 1rem;
  }
  .SmallNavBar .menuLinks {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .SmallNavBar .menuLinks .title {
    font-weight: bold;
    font-size: 0.9rem;
    margin-right: 1rem;
  }
  .SmallNavBar .menuLinks a {
    display: none;
    padding: 0rem 0.5rem;
    text-decoration: none;
    font-size: 0.8rem;
    font-weight: 400;
    color: var(--grayColor);
  }
  .SmallNavBar .userProfile {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .SmallNavBar .userProfile div {
    margin-left: 0.5rem;
  }
  .SectionOneMainPageDisplay {
    padding-right: 0rem !important;
  }
  .SectionOneMainPageDisplay .statisticsDisplay {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 1rem;
  }
  .SectionOneMainPageDisplay .statisticsDisplay .box {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.8rem 0.7rem;
  }
  .SectionOneMainPageDisplay .statisticsDisplay .box .iconContainer {
    padding: 0.8rem;
    margin-right: 0.5rem;
    border-radius: 0.3vw;
  }
  .SectionOneMainPageDisplay .statisticsDisplay .box .iconContainer .icon {
    color: var(--whiteColor);
  }
  .SectionOneMainPageDisplay .statisticsDisplay .box h3 {
    margin: 0rem;
    padding: 0rem;
  }
  .SectionOneMainPageDisplay .activityLinksCards {
    background: var(--whiteColor);
    margin-top: 0.5rem !important;
    padding: 0.5rem 0.5rem !important;
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
    grid-row-gap: 1rem;
    justify-content: center;
    align-items: center;
    width: 100% !important;
    margin-bottom: 1rem;
  }
  .SectionOneMainPageDisplay .activityLinksCards .box {
    width: 100% !important;
    margin: 0rem 0rem !important;
    padding: 0.3rem 0rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .SectionOneMainPageDisplay .activityLinksCards .box .imgCover {
    width: 50px !important;
    height: 50px !important;
    margin: 0 auto;
  }
  .SectionOneMainPageDisplay .activityLinksCards .box h2 {
    font-size: 0.7rem !important;
    text-align: center;
    padding-top: 0.2rem !important;
    text-transform: uppercase;
    font-weight: bold;
  }
  .SectionOneMainPageDisplay .activityLinksCards .box p {
    text-align: center;
    color: rgba(1, 169, 74, 0.7725490196);
    text-transform: uppercase;
    font-size: 0.5rem !important;
    font-weight: bold;
  }
}/*# sourceMappingURL=Home.css.map */