/* eslint-disable  */
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
:root {
  /* background: linear-gradient(to bottom,  20%, #015f39 100%); */
  --primaryGradient: linear-gradient(to bottom, rgb(0, 103, 221) 20%, #01325f 100%);
  --primaryGradientHover: linear-gradient(to bottom, #01325f  20%,  rgb(0, 103, 221) 100%);
  --primaryColor: #01a94b;
  --primaryColorAcent: #015f39;
  --primaryColorLite: rgba(245, 119, 69, 0.432);
  /* --secondaryColor: */
  --whiteColor: white;
  --grayColor: rgb(163, 163, 163);
  --success: rgb(0, 165, 102);
  --successDeep: rgba(0, 148, 91, 0.548);
  --successDee2: rgba(78, 211, 160, 0.548);
  --warning: rgb(221, 118, 0);
  --danger: rgb(230, 0, 0);
  --dangerHover: rgb(255, 58, 58);
  --primary: rgb(0, 103, 221);
  --primaryLite: rgb(0, 177, 221);
  --grayColor: rgb(207, 207, 207);
  --grayDeep: rgb(121, 121, 121);

  --primary-gradient: linear-gradient(to bottom, var(--primaryColor) 20%, var(--primaryColorAcent) 100%);

  --shadow: 1px 24px 85px -38px rgba(0, 0, 0, 0.658);
  --shadowHover: 1px 24px 85px -38px rgba(3, 199, 108, 0.788);

  
    --weekend: rgba(0, 0, 0, 0);
    --date-light: #f8f9fa;
    --date-primary: #5644c1;
    --date-success: #37d37d;
    --date-primaryLight: #eceaf5;
    --date-primaryTitle: #dbd8f0;
    --date-hover: #262769;
    --date-highlight: #f83854;
    --date-disabled:#c2c1cc;
    --date-width: 260px;
    --date-height: 280px;
}


.pageTitle{
  padding-top: .2rem;
  /* padding-bottom: .5rem; */
  font-size: 1.6rem;
  font-weight: 500;
}


.pagination{
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
  gap: 5px;
  font-size: 1.2rem;
  padding-top: 1.5rem;
}

.pagination .page-num{
  padding: 5px 8px;
  cursor: pointer;

  font-weight: 400;
  text-decoration: none;
}
.pagination .page-num:hover{
 
  font-weight: bold;
  background: var(--primaryColorAcent);
  color: white;
  text-decoration: none;
}
.pagination .active{
  background: var(--primaryColor);
  color: white;
  text-decoration: none;
  border-radius: 4pc;
}

.Toastify__toast-theme--light {
  /* background: var(--toastify-color-light); */
  /* color: var(--toastify-text-color-light); */
  background: black !important;
  color: white !important;
  z-index: 9999999 !important;
}

@media screen and (max-width: 500px){
  .pagination{
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    gap: 5px;
    font-size: .7rem;
    padding-top: 1.5rem;
  }
  .pageTitle{
    padding-top: 1rem;
    /* padding-bottom: .5rem; */
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center !important;
   
  }
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    /* font-family: 'Montserrat', sans-serif; */
    font-family: 'Nunito', sans-serif;
    font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
