
/* eslint-disable  */
.home_dashboard {
  background-color: white;
  display: flex;
  grid-template-columns: 15% 85%;
  position: relative;
  height: 100vh;
  // overflow: hidden;

  .DashBoardTitle{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .email_message{
      background: var(--warning);
      color: white;
      padding: 2px 10px;
      border-radius: .5rem;
    }
  }

  .SideBarMenu {
    // background: linear-gradient(to bottom, #01a94b 20%, #015f39 100%);
    background: linear-gradient(
      to bottom,
      var(--primaryColor) 20%,
      var(--primaryColorAcent) 100%
    );
    height: 100vh;
    overflow: hidden;
    min-width: 15%;
    max-width: 15%;
    width: 15%;
    background-position: left !important;

    .overlay {
      background: linear-gradient(
        to bottom,
        #01a94af1 10%,
        var(--primaryColorAcent) 100%
      );
      height: 100vh;
    }

    // width: ;
    ul {
      padding-left: 0;
      a {
        list-style: none;
        display: flex;
        align-items: center;
        padding: 0.1rem 0rem;
        margin: 0.5rem 0rem;
        text-decoration: none;
        color: var(--whiteColor);
        .icon {
          margin-left: 0.4rem;
          padding-right: 0.4rem;
        }
        &:hover {
          background: #008037;
          // border-left: .2rem solid var(--whiteColor);
          cursor: pointer;
        }
      }
    }
  }
  .MainPage {
    background: #f4f4f4;
    height: 100%;
    flex-grow: 1;
    width: 100%;
    overflow-x: hidden;

    // position: relative;
    .MainPageItem {
      height: 90vh;
      overflow-y: auto;
    }
  }
}

//SmallNavBar
.SmallNavBar {
  background: var(--whiteColor);
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  padding: 0.4rem 1rem;
  .menuLinks {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .title {
      font-weight: bold;
      font-size: 0.9rem;
      margin-right: 1rem;
    }
    a {
      padding: 0rem 0.5rem;
      text-decoration: none;
      font-size: 0.8rem;
      font-weight: 400;
      color: var(--grayColor);
    }
  }
  .userProfile {
    display: flex;
    justify-content: center;
    align-items: center;
    // justify-content: space-between;
    div {
      margin-left: 0.5rem;
    }
  }
}

//SECTIONS INSIDE MAIN COMPONENT
.MainPageSections {
  display: grid; //changed
  grid-template-columns: 75% 25%;
 
  .SectionTwoNotificationSection {
    position: relative;
    
    .NotificationPanel{
      height: 80vh;
      overflow: scroll;
      
    }
 
    .calender-box {
      background: var(--whiteColor);
      margin-bottom: 1rem;
      padding: 0.7rem;
      .dateContainer {
        position: relative;
        height: 250px;
        width: 100%;
        overflow: none;
      }
    }
    .notifications {
      background: var(--whiteColor);
      padding: 0.3rem;
      h3 {
        font-size: 1rem;
        font-weight: bold;
      }
      .flex {
        display: flex;
        align-items: center;
        padding: 0.5rem 0rem;

        .icon {
          margin-right: 0.4rem;
        }

        .notif_title {
          font-size: 0.7rem;
          margin: 0;
          font-weight: bold;

        }
        .notif_msg {
          font-size: 0.7rem;
          padding: 0.1rem 0rem;
        }
        .time {
          font-size: 0.6rem;
          color: var(--grayColor);
        }
      }
    }
    //Status section
    .carStatusDisplay {
      background: var(--whiteColor);
      padding: 0.3rem;
      h3 {
        font-size: 1rem;
        font-weight: bold;
      }
      .statusContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .flex {
        display: flex;
        align-items: center;
        padding: 0.5rem 0rem;

        .icon {
          margin-right: 0.4rem;
        }

        .notif_title {
            font-size: 0.7rem;
            margin: 0;
            font-weight: bold;
        }
        .notif_msg {
          font-size: 0.7rem;
          padding: 0.1rem 0rem;
        }
        .time {
          font-size: 0.6rem;
          color: var(--grayColor);
        }
      }
      .status {
        border-radius: 4pc;
        padding: 0rem 0.5rem;
        color: var(--whiteColor);
        font-size: 0.8rem;
      }
    }
  }

  .SectionOneMainPageDisplay {
    padding-right: 0.9rem;
    .statisticsDisplay {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 1rem;
      .card {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0.8rem 0.7rem;
        .iconContainer {
          // background: linear-gradient(to bottom, var(--primary) 20%, var(--primaryLite) 100%);
          padding: 0.8rem;
          margin-right: 0.5rem;
          border-radius: 0.3vw;
          .icon {
            color: var(--whiteColor);
          }
        }
        h3 {
          margin: 0rem;
          padding: 0rem;
        }
      }
    }

    //chartArea
    .activityLinksCards {
      background: var(--whiteColor);
      margin-top: 1rem;
      padding: 1rem 0rem;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-row-gap: 1rem;
      justify-content: center;
      align-items: center;
      .card {
        width: 90% !important;
        margin: 0rem 1rem;
        padding: 1rem 0rem;
        display: flex;
        justify-content: center;
        align-items: center;
        .imgCover {
          width: 80px;
          height: 80px;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;
          // border-radius: 50%;
          // border: 1px solid red;
          img {
            width: 70%;
          }
        }
        h2 {
          font-size: 1rem;
          text-align: center;
          padding-top: 1rem;
          text-transform: uppercase;
        }
        p {
          text-align: center;
          color: #01a94ac5;
          text-transform: uppercase;
          font-size: 0.7rem;
        }
      }
    }
  }
}

//MOBILE VIEW
@media screen and (max-width: 560px) {
  .home_dashboard {
    background-color: white;
    display: flex;
    grid-template-columns: 15% 85%;
    position: relative;
    height: auto;
    // overflow: hidden;

    .DashBoardTitle{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .email_message{
        background: var(--warning);
        color: white;
        padding: 2px 6px;
        font-size: .7rem;
        border-radius: .5rem;
      }
    }

    .SideBarMenu {
      display: block; //changed
      position: absolute;
      z-index: 9999;
      // background: linear-gradient(to bottom, var(--primaryColor) 20%, var(--primaryColorDeep) 100%);
      background: linear-gradient(to bottom, #01a94b 20%, #015f39 100%);
      height: 100vh;
      overflow: none !important;
      min-width: 50% !important;
      max-width: 50% !important;
      width: 50% !important;

      .title_nd_closeBtn {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      // width: ;
      ul {
        padding-left: 0;
        a {
          list-style: none;
          display: flex;
          align-items: center;
          padding: 0.1rem 0rem;
          margin: 0.5rem 0rem;
          text-decoration: none;
          color: var(--whiteColor);
          .icon {
            margin-left: 0.4rem;
            padding-right: 0.4rem;
          }
          &:hover {
            background: #00d15b;
            // border-left: .2rem solid var(--whiteColor);
            cursor: pointer;
          }
        }
      }
    }
    .MainPage {
      background: #f4f4f4;
      height: 100%;
      flex-grow: 1;

      // position: relative;
      .MainPageItem {
        height: auto;
      }
    }
  }
  .MainPageSections {
    display: block; //changed
  }

  //SmallNavBar
  .SmallNavBar {
    background: var(--whiteColor);
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-between;
    padding: 0.4rem 1rem;
    .menuLinks {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      //   display:  none; //changed
      .title {
        font-weight: bold;
        font-size: 0.9rem;
        margin-right: 1rem;
      }
      a {
        display: none; //changed
        padding: 0rem 0.5rem;
        text-decoration: none;
        font-size: 0.8rem;
        font-weight: 400;
        color: var(--grayColor);
      }
    }
    .userProfile {
      display: flex;
      justify-content: center;
      align-items: center;
      // justify-content: space-between;
      div {
        margin-left: 0.5rem;
      }
    }
  }

  .SectionOneMainPageDisplay {
    padding-right: 0rem !important; //changed
    .statisticsDisplay {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 1rem;
      .card {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0.8rem 0.7rem;
        .iconContainer {
          // background: linear-gradient(to bottom, var(--primary) 20%, var(--primaryLite) 100%);
          padding: 0.8rem;
          margin-right: 0.5rem;
          border-radius: 0.3vw;
          .icon {
            color: var(--whiteColor);
          }
        }
        h3 {
          margin: 0rem;
          padding: 0rem;
        }
      }
    }

    //chartArea
    .activityLinksCards {
      background: var(--whiteColor);
      margin-top: 0.5rem !important;
      padding: 0.5rem 0.5rem !important;
      display: grid;
      grid-template-columns: repeat(2, 1fr) !important;
      grid-row-gap: 1rem;
      justify-content: center;
      align-items: center;
      width: 100% !important;
      margin-bottom: 1rem;
      .card {
        width: 100% !important;
        margin: 0rem 0rem !important;
        padding: 0.3rem 0rem !important;
        display: flex;
        justify-content: center;
        align-items: center;
        .imgCover {
          width: 50px !important;
          height: 50px !important;
          margin: 0 auto;
          // border-radius: 50%;
          // border: 1px solid red;
        }
        h2 {
          font-size: 0.7rem !important;
          text-align: center;
          padding-top: 0.2rem !important;
          text-transform: uppercase;
          font-weight: bold;
        }
        p {
          text-align: center;
          color: #01a94ac5;
          text-transform: uppercase;
          font-size: 0.5rem !important;
          font-weight: bold;
        }
      }
    }
  }
}
