/* eslint-disable  */
.paginations {
  display: flex;
  justify-content: space-around;
  padding-bottom: 3rem;
  padding-top: 4rem;
  .numbers {
    display: flex;
    .number {
      padding: 1px 12px;
      margin-right: 0.3rem;
      border-radius: 4pc;
      &:hover {
        background: var(--primaryColorLite);
        cursor: pointer;
      }
    }
    .active {
      background: var(--primaryColor);
      color: white;
    }
  }
}

.searchContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem 0rem;
  .search{
    flex: 1;
    max-width: 80%;
  }
  input{
    width: 100%;
    padding: .6rem 1rem;
    border-radius: 4pc;
  }
  .searchIcon{
    background-color: var(--primaryColor);
    // padding: 1.5rem 1.5rem;
    width: 50px;
    height: 50px;
    font-size: 1rem;
    color: white;
    border-radius: 50%;
  }
}
// PRODUCTCARD
.ProductCard {
  .productInfo {
    .productImage {
      // padding: 1rem;
      img {
        border-radius: 0.5rem;
      }
    }
    .productDetailes {
      h3 {
        font-size: 1.1rem;
        margin: 0;
        padding: 0.5rem 0rem;
      }
      p {
        font-size: 0.7rem;
        width: 95%;
      }
    }
    .ProductPrice {
      .price {
        color: rgb(197, 197, 197);
      }
      .priceContent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h3 {
          font-weight: 600;
          font-size: 1.5rem;
          small {
            font-size: 0.9rem;
            text-decoration: line-through;
            color: gray;
          }
        }
      }
    }
    .addToCatBtn {
      background: var(--primaryColor);
      color: var(--whiteColor);
      // padding: .5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      .icon {
        font-size: 1.3rem;
      }
    }
  }
  .addFavourite {
    position: absolute;
    background: #f4f4f4cb;
    // padding: 0.3rem;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    right: 8px;
    top: 9px;
    cursor: pointer;
    .icon {
      font-size: 1.3rem;
    }
  }
  .card {
    border-radius: 1rem;
    padding: 0.4rem;
    position: relative;
  }
}

.ProductsPage {
  .Products {
    .productBar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .viewAll {
        background: black;
        color: white;
        padding: 1px 5px;
        border-radius: 4pc;
        font-size: 0.9rem;
        cursor: pointer;
      }
    }
  }
  .featuredProduct {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
  }

  //vehicles Section the vehicles section
  .vehicles_section {
    margin-bottom: 1rem;

    .vehicles_section_cover {
      background: var(--WhiteColor);
      height: 330px;
      border-radius: 5px 5px 0px 0px;

      .header {
        background: #1e07eebd;
        color: var(--WhiteColor);
        height: 40px;
        display: flex;
        align-items: center;
        border-radius: 5px 5px 0px 0px;
        h3 {
          padding-left: 10px;
        }
      }

      .box_container_grid {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        padding: 20px 0px;
        gap: 10px;
        .box {
          // border: 1px solid red;
          // background: white;
          .imgCover {
            .percent_off {
              background: #c5e7fc;
              font-family: calibri;
              font-size: 0.9rem;
              color: var(--lightGray);
              margin: 0px 0px 0px 12px;
            }
            // border: 1px solid blue;
            height: 170px;
            width: 100%;
            img {
              height: 170px;
            }
            .imgMainCover {
              transition: ease-in-out all 0.3s;
              &:hover {
                transform: scale(1.1);
              }
            }
          }
        }
        .descr_price {
          width: 80%;
          margin: 0 auto;
          
        }
        .descr {
          color: var(--lightGray);
          font-size: 0.8rem;
          text-align: center;
        }
        .price {
          text-align: center;
          font-weight: bold;
          font-size: 0.8rem;
          padding: 5px 0px;
          color: var(--grayColor);
        }
      }
    }
  }

  // .ProductCover{
  //     display: grid;
  //     grid-template-columns: 85% 15%;
  //     .prodctsSection{

  //     }
  //     .ProductFilters{
  //         // max-width: 40%;
  //         // max-height: 40%;
  //         // width: 40%;
  //         // flex-grow: 1;
  //         // background-color: red;
  //     }
  // }

  //   .productsContainer {
  //     display: grid;
  //     grid-template-columns: repeat(4, 1fr);
  //     grid-column-gap: 1rem;
  //     grid-row-gap: 1rem;
  //   }
}
.AllCategoryShowProducts {
  .productCoverList{
    .boxList{
      display: flex;
      background: white;
      padding: .5rem;
      margin-bottom: 1rem;
      position: relative;
      img{
        width: 150px;
        height: 100px;
      }
      .percent_off {
        background: #c5e7fc;
        font-family: calibri;
        font-size: 0.9rem;
        color: var(--lightGray);
        margin: 0px 0px 0px 12px;
        position: absolute;
        right: 0;
      }
      .title{
        font-weight: bold;
      }
      .descr{
        // font-size: .8rem;
      }
      .price{
        text-align: right;
        font-weight: bold;
        color: #015f39;
        font-size: 1.2rem;
      }
    }
  }

  .searchAndGridList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    .searchBar {
      input {
        outline: none;
        border: none;
        padding: 0.5rem;
        width: 400px;
        border-radius: 4pc;
        padding-right: 2.5rem;
      }
      button {
        border: none;
        background: var(--primaryColor);
        padding: 0.5rem;
        border-radius: 4pc;
        width: 100px;
        position: relative;
        right: 30px;
      }
    }
  }
  //vehicles Section the vehicles section
  .productCover {
    margin-bottom: 3rem;

    .vehicles_section_cover {
      background: var(--WhiteColor);
      // height: 330px;
      border-radius: 5px 5px 0px 0px;

      .header {
        background: #1e07eebd;
        color: var(--WhiteColor);
        height: 40px;
        display: flex;
        align-items: center;
        border-radius: 5px 5px 0px 0px;
        h3 {
          padding-left: 10px;
        }
      }

      .box_container_grid {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        padding: 20px 0px;
        grid-row-gap: 6rem;
        .box {
          // border: 1px solid red;
          .imgCover {
            .percent_off {
              background: #c5e7fc;
              font-family: calibri;
              font-size: 0.9rem;
              color: var(--lightGray);
              margin: 0px 0px 0px 12px;
            }
            // border: 1px solid blue;
            height: 170px;
            width: 100%;
            img {
              height: 170px;
            }
            .imgMainCover {
              transition: ease-in-out all 0.3s;
              &:hover {
                transform: scale(1.2);
              }
            }
          }
        }
        .descr_price {
          width: 80%;
          margin: 0 auto;
        }
        .descr {
          color: var(--lightGray);
          font-size: 0.8rem;
        }
        .price {
          text-align: center;
          font-weight: bold;
          font-size: 0.8rem;
          padding: 5px 0px;
          color: var(--grayColor);
        }
      }
    }
  }

}

.FavouriteProducts {
  .favouriteContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    img{
      height: 150px;
    }
  }
}

.ProductSlider {
    margin-bottom: 2rem;
  .slider {
    height: 400px;
  }
  .carousel .slide .legend{
    background: transparent;
    font-size: 2rem;
    opacity: 1;
    margin-bottom: 0px;
    
  }
}

.MyCart {
  .cartProducts:nth-child(even){
    background: rgb(255, 251, 251);
  }
  .cartProducts {
    display: grid;
    grid-template-columns: 15% 85%;
    // flex-direction: column;
    margin-top: 1rem;
    padding: 5px;
   
    .productImage {
      flex-grow: 1;
      img{
        height: 100px;
        width: 80%;
      }
    }
    .TitlePrice {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .productTitle {
        color: rgb(156, 156, 156);
        h2{
          font-size: 1.3rem;
        }
      }
      .ProductPrice {
        font-size: 1rem;
        font-weight: 500;
      }
    }
    .stock {
      display: flex;
      // gap: 10rem;
      align-items: center;
      margin-bottom: 1rem;
      .PriceCance {
        text-decoration: line-through;
        color: gray;
      }
      .inStock {
        color: var(--success);
      }
      > *{
        flex-basis: 1;
        flex: 1;
      }
      select{
        
       box-shadow: 1px 24px 85px -38px rgba(0, 0, 0, 0.658);
      }
    }
    .FilterAndDelete {
      display: flex;
      justify-content: space-between;
      .quantityFilters {
      }
      .sandAndDeleteButtons {
        .btn {
          color: gray;
          font-size: 0.8rem;
        }
        .icon {
          font-size: 1rem;
        }
      }
    }
    .quantityFilters {
      display: flex;
      .sizes {
        select {
          padding: 0.3rem 0.7rem;
          margin-right: 0.5rem;
          border: none;
          border-radius: 0.4vw;
          width: auto;
        }
      }
      .colors {
        select {
          padding: 0.3rem 0.7rem;
          margin-right: 0.5rem;
          border: none;
          border-radius: 0.4vw;
        }
      }
      .quantity {
        padding: 0.3rem 0.7rem;
        .minus {
          background: white;
          padding: 0.3rem 0.7rem;
        }
        .plus {
          background: white;
          padding: 0.3rem 0.7rem;
        }
      }
    }
    .sandAndDeleteButtons {
      display: flex;
    }
  }
  .TotalProducts{
    display: flex;
    justify-content: flex-end;
    .item{
      width: 30%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .totalText{
        font-size: 1.7rem;
      }
      .totalPrice{
        font-size: 1.7rem;
        font-weight: bold;
      }
    }
  }

  .confirmBtn {
    display: flex;
    justify-content: flex-end;
  }
}

.ProductDetailed {
  .backBtn{
    background: gray;
    padding: 0rem .5rem;
    border-radius: 4pc;
    color: white;

  }
  .imageAndInfo {
    display: grid;

    // height: 70vh;
    // max-height: 70vh;
    grid-template-columns: repeat(2, 1fr);
    .imageSection {
      background: white;
      .carousel.carousel-slider {
        height: 400px;
        img {
          height: 400px;
        }
      }
      .carousel .slide .legend {
        display: none;
      }
      .carousel .thumbs-wrapper {
        margin: 0px;
        img {
          height: 50px;
        }
      }
      // background: red;
      // height: 70vh;
      .slider {
        // height: 70vh;
        img {
          height: 100%;
        }
      }
    }
    .smallInfoSection {
      padding: 1rem;
      // background: green;
      .productTitle {
        font-weight: 500;
        color: rgb(83, 83, 83);
      }
      .small_description {
        background: var(--whiteColor);
        padding: 0.5rem;
        border-radius: 0.4vw;
      }
      .stockAvialable {
        color: var(--success);
      }
      .outOfStock {
        color: var(--warning);
      }
      .price {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1;
        .currentPrice {
          font-size: 2rem;
          margin-right: 1rem;
          font-weight: 500;
        }
        .oldPrice {
          text-decoration: line-through;
          color: rgb(170, 170, 170);
        }

        .priceDuration{
        //  width: 100%;
        select{
          padding: 15px;
        }
        }
      }
      .sizeFilters {
        .title {
          padding-top: 0.8rem;
        }
        .sizes {
          display: flex;
          align-items: center;
          margin: 1rem 0rem;
          div {
            display: flex;
            background: white;
            margin-right: 1rem;
            border: 2px solid var(--primaryColorLite);
            // border: 1px solid rgba(204, 204, 204, 0.925);
            width: 40px;
            height: 40px;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
          }
          
        }
        // .activeSize{
        //   border: 2px solid var(--primaryColor);
        // }
      }
      .colorFilters {
        .title {
          padding-top: 0.8rem;
        }
        .colors {
          display: flex;
          align-items: center;
          margin: 1rem 0rem;
          div {
            display: flex;
            background: white;
            margin-right: 1rem;
            border: none;
            width: 60px;
            height: 40px;
            border-radius: 0.5vw;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .quantity {
        input {
          width: 20%;
        }
      }
      .cartBtn {
        text-align: end;
        margin-top: 0.5rem;
        button {
          background: linear-gradient(to bottom, #01a94b 20%, #015f39 100%);

          border: none;
          padding: 1rem;
          width: 30%;
          color: white;
        }
        .favourite {
          background: rgb(39, 39, 39);
          margin-right: 1rem;
          border: none;
          padding: 1rem 0rem;
          width: 30%;
          color: white;
        }
      }
    }
  }
  .detailDescription {
  }
  .productImages {
    img {
      width: 60%;
      margin-bottom: 1rem;
      border-radius: 0.6rem;
    }
  }
}

//MOBILE STYLES
@media screen and (max-width: 500px) {
    .paginations {
        display: flex;
        justify-content: space-around;
        margin-bottom: 3rem;
        margin-top: 4rem;
        width: 300px;
        max-width: 300px;
        margin: 0 auto;
        .numbers {
          display: flex;
          width: 50%;
          overflow-x: scroll;
          .number {
            padding: 1px 12px;
            margin-right: 0.3rem;
            border-radius: 4pc;
            &:hover {
              background: var(--primaryColorLite);
              cursor: pointer;
            }
          }
          .active {
            background: var(--primaryColor);
            color: white;
          }
        }
      }
      //vehicles Section the vehicles section

      //search
      .searchContainer{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 1rem 0rem;
        .search{
          flex: 1;
          max-width: 80%;
        }
        input{
          width: 100%;
          padding: .5rem 1rem;
          border-radius: 4pc;
        }
        .searchIcon{
          background-color: var(--primaryColor);
          // padding: 1.5rem 1.5rem;
          width: 50px;
          height: 50px;
          font-size: 1rem;
          color: white;
          border-radius: 50%;
        }
      }

      .FavouriteProducts {
        .favouriteContainer {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-column-gap: 1rem;
          grid-row-gap: 1rem;
        }
      }


  .vehicles_section {
    margin-bottom: 1rem;

    .vehicles_section_cover {
      background: var(--WhiteColor);
      height: auto !important;
      border-radius: 5px 5px 0px 0px;

      .header {
        background: #1e07eebd;
        color: var(--WhiteColor);
        height: 40px;
        display: flex;
        align-items: center;
        border-radius: 5px 5px 0px 0px;
        h3 {
          padding-left: 10px;
        }
      }

      .box_container_grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr) !important;
        padding: 20px 0px;
        .box {
          // border: 1px solid red;
          .imgCover {
            
            .percent_off {
              background: #c5e7fc;
              font-family: calibri;
              font-size: 0.9rem;
              color: var(--lightGray);
              margin: 0px 0px 0px 12px;
            }
            // border: 1px solid blue;
            height: auto !important;
            width: 100%;
            img {
              height: 100px !important;
            }
            .imgMainCover {
              transition: ease-in-out all 0.3s;
              &:hover {
                transform: scale(1.2);
              }
            }
          }
        }
        .descr_price {
          width: 80%;
          margin: 0 auto;
        }
        .descr {
          color: var(--lightGray);
          font-size: 0.8rem;
        }
        .price {
          text-align: center;
          font-weight: bold;
          font-size: 0.8rem;
          padding: 5px 0px;
          color: var(--grayColor);
        }
      }
    }
  }

  .ProductSlider {
    .slider {
      height: 250px;
      img{
          height: 250px;
      }
    }
    .carousel .slide .legend{
        background: transparent;
      font-size: 1rem;
      opacity: 1;
    }
  }


  .ProductDetailed {
      width: 100%;
      overflow: none;
     
    .imageAndInfo {
      display: block; //changed
      grid-template-columns: repeat(2, 1fr);
      width: 100%;
      .imageSection {
        background: white;
        .carousel.carousel-slider {
          height: 250px;
          img {
            height: 250px;
          }
        }
        .carousel .slide .legend {
          display: none;
        }
        .carousel .thumbs-wrapper {
          margin: 0px;
          img {
            height: 50px;
          }
        }
        // background: red;
        // height: 70vh;
        .slider {
          // height: 70vh;
          img {
            height: 100%;
          }
        }
      }
      .smallInfoSection {
        padding: 1rem;
        // background: green;
        .productTitle {
          font-weight: 500;
          color: black;
          font-size: 1rem;
          font-weight: 600;
        }
        .small_description {
          background: var(--whiteColor);
          padding: 0.5rem;
          border-radius: 0.4vw;
          font-size: .9rem;
        }
        .stockAvialable {
          color: var(--success);
        }
        .outOfStock {
          color: var(--warning);
        }
        .price {
          display: flex;
          align-items: center;
          .currentPrice {
            font-size: 2rem;
            margin-right: 1rem;
            font-weight: 500;
          }
          .oldPrice {
            text-decoration: line-through;
            color: rgb(170, 170, 170);
          }
        }
        .sizeFilters {
          .title {
            padding-top: 0.8rem;
          }
          .sizes {
            display: flex;
            align-items: center;
            margin: .8rem 0rem;
           
            div {
                font-size: .8rem;
              display: flex;
              background: white;
              margin-right: .3rem;
              border: 1px solid rgba(204, 204, 204, 0.925);
              width: 40px;
              height: 40px;
              border-radius: 50%;
              align-items: center;
              justify-content: center;
            }
          }
        }
        .colorFilters {
          .title {
            padding-top: 0.8rem;
          }
          .colors {
            display: flex;
            align-items: center;
            margin: 1rem 0rem;
            div {
              display: flex;
              background: white;
              margin-right: 1rem;
              border: none;
              width: 34px;
              height: 23px;
              border-radius: 0.5vw;
              align-items: center;
              justify-content: center;
            }
          }
        }
        .quantity {
          input {
            width: 20%;
          }
        }
        .cartBtn {
          text-align: end;
          margin-top: 0.5rem;
          button {
            background: linear-gradient(to bottom, #01a94b 20%, #015f39 100%);
            font-size: .7rem;
            border: none;
            padding: .5rem;
            width: auto;
            color: white;
          }
          .favourite {
            background: rgb(39, 39, 39);
            margin-right: 1rem;
            border: none;
            padding: .5rem .3rem;
            width: auto;
            color: white;
          }
        }
      }
    }
    .detailDescription {
        font-size: .8rem;
    }
    .productImages {
      img {
        width: 100%;
        margin-bottom: 1rem;
        border-radius: 0.6rem;
      }
    }
  }
  

  .AllCategoryShowProducts {
    .boxList{
      display: flex;
      background: white;
      padding: .5rem;
      margin-bottom: 1rem;
      position: relative;
      img{
        width: 100px !important;
        height: 60px !important;
      }
      .percent_off {
        background: #c5e7fc;
        font-family: calibri;
        font-size: 0.9rem;
        color: var(--lightGray);
        margin: 0px 0px 0px 12px;
        position: absolute;
        left: 0;
        width: 10%;
        right: none !important;
      }
      .title{
        font-weight: bold;
      }
      .descr{
        font-size: .8rem;
      }
      .price{
        text-align: right;
        font-weight: bold;
        color: #015f39;
      }
    }
    .searchAndGridList {
      display: block;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
 
      .searchBar {
        input {
          outline: none;
          border: none;
          padding: 0.5rem;
          width: auto;
          border-radius: 4pc;
          padding-right: 2.5rem;
        }
        button {
          border: none;
          background: var(--primaryColor);
          padding: 0.5rem;
          border-radius: 4pc;
          width: auto;
          position: none;
          right: 30px;
        }
      }
      .listNdGridIcons{
        text-align: right;
        margin-top: 1rem;
      }
    }
    //vehicles Section the vehicles section
    .productCover {
      margin-bottom: 3rem;
  
      .vehicles_section_cover {
        background: var(--WhiteColor);
        // height: 330px;
        border-radius: 5px 5px 0px 0px;
  
        .header {
          background: #1e07eebd;
          color: var(--WhiteColor);
          height: 40px;
          display: flex;
          align-items: center;
          border-radius: 5px 5px 0px 0px;
          h3 {
            padding-left: 10px;
          }
        }
  
        .box_container_grid {
          display: grid;
          grid-template-columns: repeat(2, 1fr) !important;
          padding: 20px 0px;
          grid-row-gap: 6rem;
          .box {
            // border: 1px solid red;
            .imgCover {
              .percent_off {
                background: #c5e7fc;
                font-family: calibri;
                font-size: 0.9rem;
                color: var(--lightGray);
                margin: 0px 0px 0px 12px;
              }
              // border: 1px solid blue;
              height: 100px;
              width: 100%;
              img {
                height: 100px;
              }
              .imgMainCover {
                transition: ease-in-out all 0.3s;
                &:hover {
                  transform: scale(1.2);
                }
              }
            }
          }
          .descr_price {
            width: 80%;
            margin: 0 auto;
          }
          .descr {
            color: var(--lightGray);
            font-size: 0.8rem;
          }
          .price {
            text-align: center;
            font-weight: bold;
            font-size: 0.8rem;
            padding: 5px 0px;
            color: var(--grayColor);
          }
        }
      }
    }
  
  }


  .MyCart {
    .cartProducts {
      display: grid;
      grid-template-columns: 20% 80%;
      .productImage {
        flex-grow: 1;
        img{
          height: 50px;
          width: 100%;
        }
      }
      .TitlePrice {
        .productTitle {
          color: rgb(156, 156, 156);
          h2{
            font-size: 1rem;
          }
        }
        .ProductPrice {
          font-size: .9rem;
          font-weight: 500;
        }
      }
      .stock {
        margin-bottom: 1rem;
        font-size: .8rem;
        .PriceCance {
          text-decoration: line-through;
          color: gray;
          font-size: .8rem;
        }
        .inStock {
          color: var(--success);
        }
      }
      .FilterAndDelete {
        display: flex;
        justify-content: space-between;
        .quantityFilters {
        }
        .sandAndDeleteButtons {
          .btn {
            color: gray;
            font-size: 0.7rem;
          }
          .icon {
            font-size: 1rem;
          }
        }
      }
      .quantityFilters {
        display: flex;
        .sizes {
          select {
            padding: 0.3rem 0rem;
            margin-right: 0.5rem;
            border: none;
            border-radius: 0.4vw;
            font-size: .7rem;
          }
        }
        .colors {
          select {
            padding: 0.3rem 0rem;
            margin-right: 0.5rem;
            border: none;
            border-radius: 0.4vw;
            font-size: .7rem;
          }
        }
        .quantity {
          padding: 0.3rem 0rem;
          font-size: .8rem;
          .minus {
            background: white;
            padding: 0.3rem 0.4rem;
          }
          .plus {
            background: white;
            padding: 0.3rem 0.4rem;
          }
        }
      }
      .sandAndDeleteButtons {
        display: flex;
      }
    }
    .TotalProducts{
      display: flex;
      justify-content: flex-end;
      margin-top: .5rem;
      .item{
        width: 40%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .totalText{
          font-size: 1rem;
        }
        .totalPrice{
          font-size: 1rem;
          font-weight: bold;
        }
      }
    }

    .phoneIcon{
      position: relative;
      top: -30px;
      margin-left: 1rem;
    }
  
    .confirmBtn {
      display: flex;
      justify-content: flex-end;
      font-size: .8rem;
    }
  }

}
