/* eslint-disable  */
.SearchedProduct{
    
}
.SearchedProduct .searchedContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
}
.SearchedProduct .productImage{
  height: 100px;
  width: 180px;
  margin:  0 auto;
}
.SearchedProduct .productImage .image{
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  margin:  0 auto;
}

.SearchedProduct .searchContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1rem 0rem;
    justify-content: center;
   
 
}
.SearchedProduct .search{
    flex: 1;
    max-width: 80%;
    display: flex;
    align-items: center;
  }
.SearchedProduct input{
    width: 100%;
    padding: .5rem 1rem;
    border-radius: 4pc;
  }
.SearchedProduct .searchIcon{
  background-color: var(--primaryColor);
   /* padding: 1.5rem 1.5rem; */
  width: 50px;
  height: 50px;
  font-size: 1rem;
  color: white;
  border-radius: 50%;
}
.SearchedProduct  .button{
  background-color: var(--primaryColor);
   /* padding: 1.5rem 1.5rem; */
 
}

@media screen  and (max-width: 500px){
    .SearchedProduct .searchedContainer {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
    }
    .SearchedProduct .image{
      height: 100px;
    }  
    .SearchedProduct input{
        width: 100%;
        padding: .5rem 1rem;
        border-radius: 4pc;
      }
}