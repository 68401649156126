/* eslint-disable  */
.App {
}
img {
  width: 100%;
  /* height: 100%; */
}
a {
  color: inherit;
  text-decoration: none;
}
.button {
  display: block;
  padding: 0.5rem 1rem;
  border-radius: 0.4vw;
  color: var(--whiteColor);
  border: none;
  background: var(--primaryColor);
}
.backbtn{
  background: rgb(114, 114, 114);
  padding: 1px 10px;
  margin-top: .2rem;
  border-radius: 4pc;
  color: white;
  cursor: pointer;
}
.button_cancel {
  display: block;
  padding: 0.5rem 1rem;
  border-radius: 0.4vw;
  color: black;
  border: none;
  background: #b8b8b8;
}
.flex {
  display: flex;
  justify-items: center;
}
.mt1 {
  margin-top: 0.5rem;
}
.mt2 {
  margin-top: 1rem;
}
.mt3 {
  margin-top: 1.5rem;
}
.mt4 {
  margin-top: 2rem;
}
.mb1 {
  margin-bottom: 0.5rem;
}
.mb2 {
  margin-bottom: 1rem;
}
.mb3 {
  margin-bottom: 1.5rem;
}
.mb4 {
  margin-bottom: 2rem;
}

.pt1 {
  padding-top: 0.5rem;
}
.pt2 {
  padding-top: 1rem;
}
.pt3 {
  padding-top: 1.5rem;
}
.pt4 {
  padding-top: 2rem;
}
.pb1 {
  padding-bottom: 0.5rem;
}
.pb2 {
  padding-bottom: 1rem;
}
.pb3 {
  padding-bottom: 1.5rem;
}
.pb4 {
  padding-bottom: 2rem;
}

.hide_on_large {
  display: none;
}
.hide_on_small {
  display: none;
}

.Loader {
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  z-index: 9999;
  height: 100%;

  /* width: 100%;
    height: 100%; */
}
.Loader .overlay {
  background-color: rgba(255, 255, 255, 0.692);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.videoPlayer {
  max-width: 100%;
  height: 500px !important;
  width: 100%;
}
label {
  font-weight: bold;
  padding-bottom: 2px;
  padding-top: 3px;
}
@media screen and (max-width: 560px) {
  .hide_on_small {
    display: none;
  }
  .hide_on_large {
    display: block;
  }

  .backbtn{
    background: rgb(114, 114, 114);
    padding: 1px 10px;
    margin-top: 0rem;
    border-radius: 4pc;
    font-size: .8rem;
    color: white;
    cursor: pointer;
  }
  .backbtn:hover{
    background: rgb(3, 3, 3);
  }
}
