.CalContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}
.CalContainer .calWrapper{
 width: 60%;
}

.CalContainer .calcResults{
    border: 1px solid var(--success);
    height: 100px;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

}
.CalContainer .calcResults h1{
    font-size: 2.5rem;
}
.CalContainer .refresh-icon{
    position: absolute;
    left: 440px;
    top: 40px;
    cursor: pointer;
    font-size: 2rem;
    opacity: 0.3;
    transition: all ease-in-out .2s;
}
.CalContainer .refresh-icon:hover{
   
    opacity: 1;
}

@media screen and (max-width: 500px) {
    .CalContainer{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .CalContainer .calWrapper{
        width: 100%;
       }
    .CalContainer .calWrapper .decr{
        font-size: .8rem;
        padding-bottom: .9rem;
       }

       .CalContainer .calBtn{
          margin-top: 10px;
       }
    
    .CalContainer .calcResults{
        border: 1px solid var(--success);
        height: 55px;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    
    }
    .CalContainer .calcResults h1{
        font-size: 2rem;
    }
    .CalContainer .refresh-icon{
        position: absolute;
        left: 220px;
        top: 10px;
        cursor: pointer;
        font-size: 2rem;
        opacity: 0.3;
        transition: all ease-in-out .2s;
    }
    .CalContainer .refresh-icon:hover{
        opacity: 1;
    }
}