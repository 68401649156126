/* eslint-disable  */
// Profile page styles

.ProfilePage{
    .photos{
        position: relative;
        .coverPhoto{
            height: 230px;
            width: 100%;
            // background-color: gray;
            // max-width: 100%;
            // max-height: 230px;
           border-radius: 2rem 0rem 0rem 0rem;
           display: flex;
           justify-content: center;
           align-items: center;
        //    border: 5px solid red;
           overflow: hidden;

           img{
           width: 100%;
           height: 100%;
           object-fit: cover;
           }
           
        }
        .iconUploadButton{
            background: rgba(255, 255, 255, 0.658);
            border-radius: 50%;
            position: absolute;
        }
        .profileImage{
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            // top: 20px;
            right: 0;
            left: 0;
            top: -24px;
            .profile{
                display: flex;
                align-items: center;
                // .pCover{
                //     background: white;
                //     height: 150px;
                //     width: 150px;
                //     border-radius: 50%;
                // }
                .profileImgCover{
                    // border: .2rem solid var(--primaryColor);
                    border: .3rem solid #f4f4f4;
                    background: white;
                    height: 150px;
                    width: 150px;
                    overflow: hidden;
                    border-radius: 50%;
                    margin-right: 1rem;
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                    
                }
            };
            .profile_btn{
                display: flex;
                .cancelBtn{
                    margin-right: 1rem;
                }
            }
          
        }


    .iconUploadButton {
      background: rgba(255, 255, 255, 0.658);
      border-radius: 50%;
    }
    .profileImage {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      // top: 20px;
      right: 0;
      left: 0;
      top: -24px;
      .profile {
        display: flex;
        align-items: center;
        .pCover {
          background: white;
          height: 150px;
          width: 150px;
          border-radius: 50%;
        }
        .profileImgCover {
          // border: .2rem solid var(--primaryColor);
          border: 0.3rem solid #f4f4f4;
          background: white;
          height: 150px;
          width: 150px;
          border-radius: 50%;
          margin-right: 1rem;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          img {
            width: 150px;
            height: 150px;
          }
        }
      }
      .profile_btn {
        display: flex;
        .cancelBtn {
          margin-right: 1rem;
        }
      }
    }
  }

  .profileDetails {
    .gridBox {
      display: grid;
      grid-template-columns: 30% 70%;
      align-items: center;
      margin-bottom: 1rem;
    }
    width: 75%;
    margin: 0 auto;
    .username {
      flex-grow: 1;
    }
  }
}

.NotificationsPage .notifications {
  width: 70%;
  margin: 0 auto;
  padding: 0.3rem;
  h3 {
    font-size: 1.3rem;
  }
  .flex {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    background: var(--whiteColor);
    margin-bottom: 1rem;
    border-radius: 0.7vw;

    .icon {
      margin-right: 0.4rem;
    }

    .notif_title {
      font-size: 0.9rem;
      margin: 0;
      font-weight: 500;
    }
    .notif_msg {
      font-size: 0.7rem;
      padding: 0.1rem 0rem;
    }
    .time {
      font-size: 0.6rem;
      color: var(--grayColor);
    }
  }
}

.LoansPage {
  .searchAndGridList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    .searchBar {
      input {
        outline: none;
        border: none;
        padding: 0.5rem;
        width: 400px;
        border-radius: 4pc;
        padding-right: 2.5rem;
      }
      button {
        border: none;
        background: var(--primaryColor);
        padding: 0.5rem;
        border-radius: 4pc;
        width: 100px;
        position: relative;
        right: 30px;
      }
    }
  }
}

.ConfirmEmailPassword {
  background: url("/public/images/dev/nurse-float.jpeg") no-repeat center
    center/cover;
  height: 100vh;
  width: 100%;
  .overlay {
    background: rgba(255, 255, 255, 0.877);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    form {
      background: rgba(255, 255, 255, 0.781);
      width: 37%;
      padding: 2rem 1rem;
      .title {
        width: 80%;
        margin: 0 auto;
        text-align: center;
        // font-size: 2rem;
        margin-bottom: 1rem;
        font-weight: 400;
        color: rgb(80, 80, 80);
      }
    }
    .inputGroup {
      position: relative;
      .icon {
        position: absolute;
        right: 10px;
        top: 8px;
        color: rgb(175, 175, 175);
        cursor: pointer;
      }
    }
    input {
      width: 100%;
      padding: 0.5rem;
      margin-bottom: 0.8rem;
    }
  }

  .profile_email_dob_container {
    display: grid;
    grid-template-columns: 60% 40%;
    grid-column-gap: 10px;
  }
}

.MyOrdersNdPurchases {
  background-color: white !important;
  .item {
    display: flex;
    // grid-template-columns: 80% 20%;
    align-items: center;
    justify-content: space-around;
    box-shadow: 1px 5px 15px rgba(110, 110, 110, 0.5);
    margin-bottom: 1rem;
    margin-top: 1rem;
    .item1 {
      // flex: 1;
      width: 90%;
      .title {
        font-size: 1rem;
      }
      .value {
        font-weight: bold;
      }
    }
  }
  .summary {
    .flex {
      margin-bottom: 0.7rem;
      width: 30%;
      justify-content: space-between;
    }
  }
  .status {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 1rem;
    .card {
      padding: 0.4rem;
      .status_tracker {
        border: 5px solid rgba(197, 197, 197, 0.842);
        border-radius: 4pc;
        margin: 1rem 0rem 0.2rem 0rem;
      }
    }

    .readMessageContainer {
      position: absolute;
      right: 0;
    }

    .readMsg {
      position: absolute;
      right: 0;
      bottom: 25px;
      font-size: 0.7rem;
      color: red;
    }
    .messageIcon {
      color: red;
      font-size: 3rem;
    }
  }

  .MyOrders {
    margin-top: 2rem;
    .order {
      display: flex;
      img {
        width: 200px;
      }
      .info {
        margin-left: 1rem;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .NotificationsPage .notifications {
    width: 100%;
    .flex {
      margin-bottom: 0.7rem;
      border-radius: 1vw !important;
    }
  }
  // Profile page styles
  .ProfilePage {
    .photos {
      position: relative;
      .coverPhoto {
        height: 200px;
        width: 100%;
        // background-color: gray;
        max-width: 100%;
        max-height: 230px;
        border-radius: 2rem 0rem 0rem 0rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .iconUploadButton {
        background: rgba(255, 255, 255, 0.658);
        border-radius: 50%;
      }
      .profileImage {
        display: block;
        justify-content: space-between;
        align-items: center;
        position: relative;
        // top: 20px;
        right: 0;
        left: 0;
        top: -4px;
        .profile {
          display: block;
          align-items: center;
          .pCover {
            background: transparent;
            height: 100px;
            width: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            border-radius: 50%;
            .iconUploadButton {
              background: transparent;
            }
          }
          .profileImgCover {
            // border: .2rem solid var(--primaryColor);
            border: 0.3rem solid #f4f4f4;
            background: white;
            height: 100px;
            width: 100px;
            border-radius: 50%;
            margin-right: 1rem;
            display: flex;
            justify-content: center;
            align-items: flex-end;

            img {
              width: 100px;
              height: 100px;
            }
          }
        }
        .profile_btn {
          display: flex;

          justify-content: right;
          .cancelBtn {
            margin-right: 0.6rem;
          }
        }
      }
    }

    .profileDetails {
      margin-top: 1rem !important;
      .gridBox {
        display: grid;
        grid-template-columns: 30% 70%;
        align-items: center;
        margin-bottom: 1rem;
      }
      width: 100%;
      margin: 0 auto;
      .username {
        flex-grow: 1;
      }
    }
  }

  .LoansPage {
    .searchAndGridList {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      .searchBar {
        input {
          outline: none;
          border: none;
          padding: 0.5rem;
          width: auto;
          border-radius: 4pc;
          padding-right: 2.5rem;
        }
        button {
          border: none;
          background: var(--primaryColor);
          padding: 0.5rem;
          border-radius: 4pc;
          width: 100px;
          position: relative;
          right: 30px;
        }
      }
    }
    .duesTableContainer {
      // background: red;
      overflow: hidden;
      max-width: 100%;
      width: 100%;
      overflow-x: scroll;
    }
  }

  .ConfirmEmailPassword {
    background: url("/public/images/dev/nurse-float.jpeg") no-repeat center
      center/cover;
    height: 100vh;
    width: 100%;
    .overlay {
      background: rgba(255, 255, 255, 0.74);

      form {
        background: rgba(255, 255, 255, 0.781);
        width: 90%;
        padding: 2rem 1rem;
        .title {
          width: 90%;
          margin: 0 auto;
          text-align: center;
          // font-size: 2rem;
          margin-bottom: 1rem;
          font-weight: 400;
          color: rgb(80, 80, 80);
        }
      }
      .inputGroup {
        position: relative;
        .icon {
          position: absolute;
          right: 10px;
          top: 8px;
          color: rgb(175, 175, 175);
          cursor: pointer;
        }
      }
      input {
        width: 100%;
        padding: 0.5rem;
        margin-bottom: 0.8rem;
      }
    }
    .profile_email_dob_container {
      display: block;
      margin-bottom: 1rem;
    }
  }

  .MyOrdersNdPurchases {
    background-color: white !important;
    .item {
      padding: 5px;
      display: block;
      // grid-template-columns: 80% 20%;
      align-items: center;
      justify-content: space-around;
      box-shadow: 1px 5px 15px rgba(110, 110, 110, 0.5);
      margin-bottom: 1rem;
      margin-top: 1rem;
      .item1 {
        // flex: 1;
        width: 95%;
        .title {
            font-size: .8rem;
          }
          .value {
            font-weight: bold;
            font-size: .8rem;
          }
      }
    }
    .summary {
      .flex {
        margin-bottom: 0.2rem;
        width: auto;
        justify-content: space-between;
      }
    }
    .status {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 1rem;
      grid-row-gap: 0.5rem;

      .card {
        padding: 0.2rem;
        .status_tracker {
          border: 5px solid rgba(197, 197, 197, 0.842);
          border-radius: 4pc;
          margin: 1rem 0rem 0.2rem 0rem;
        }
        .status_name,
        .status_date {
          font-size: 0.6rem;
        }
      }
      .readMessageContainer {
        position: absolute;
        bottom: 20px;
      }

      .readMsg {
        position: absolute;
        left: 0;
        bottom: 20px;
        font-size: 0.5rem;
        color: red;
      }
      .messageIcon {
        color: red;
        font-size: 1.5rem;
      }
    }

    .deleteBtn {
      display: flex;
      justify-content: flex-end;
    }

    .MyOrders {
      margin-top: 2rem;
      .order {
        display: flex;
        img {
          width: 200px;
        }
        .info {
          margin-left: 1rem;
        }
      }
    }
  }
}
