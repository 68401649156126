.listNdGridIcons{
    display: flex;
    justify-content: space-between;
    /* width: 18%; */
    /* display: none; */
}

.FundPage .searchAndGridList{
    display: flex;
 margin: 1rem 0rem;
 justify-content: space-between;
 align-items: center;
   
}
.FundPage .searchCover{
    width: 70%;
}
.FundPage .listNdGridIcons{
    /* width: 30%; */
    /* padding: 0rem 2rem; */

}
.FundPage .listNdGridIcons .icon{
    /* width: 30%; */
    margin: 0rem 1.5rem;
    font-size: 2rem;

}
.FundPage .searchCover input{
    outline: none;
    width: 100%;
        border: none;
        padding: .5rem .5rem;
        
        border-radius: 4pc;
      
}

@media screen and (max-width: 500px){
    .fundsTableContainer .header{
        font-size: .7rem;
        }
    .fundsTableContainer .data{
        font-size: .7rem;
        }

     .searchCover{

         /* width: 100% !important; */
     }
  
    .FundPage .searchAndGridList{
        display: flex;
     margin: 1rem 0rem;
     justify-content: space-between;
     align-items: center;
       
    }
    .FundPage .searchCover{
        width: 80%;
    }

.FundPage .listNdGridIcons .icon{
    /* width: 30%; */
    margin: 0rem .8rem;
    font-size: 1.3rem;

}
.FundPage .searchCover input{
 padding: .3rem .5rem;
    font-size: .9rem;

}
}