.cart_chip{
    display: flex;
    justify-content: center;
    align-items: center;
   }
   
   .cart_chip .chip_container{
     display: flex;
     justify-content: center;
     align-items: center;
     flex-wrap: wrap;
     padding: 10px;
     
   }
   .cart_chip .chip_container .chip_card{
     position: relative;
     width: auto;
     height: 30px;
     display: flex;
     
   justify-content: center;
   align-items: center;
   color: white;
     /* margin: 30px; */
     /* background: #287bff; */
     background: gray;
     border-radius: 10px;
     border-top-right-radius: 160px;
     border-bottom-right-radius: 160px;
     box-shadow: 8px 0px 0 #bdbdbd6e,
     inset -8px 0 0 rgba(255, 255, 255, 0.25), 
     8px 0 0 rgba(0, 0, 0, 0.15);
   
   }
   .cart_chip .chip_container .chip_card::before{
     content: '';
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     /* height: ; */
     
   
   }
   .cart_chip .chip_container .chip_card .chip_content{
     padding: 0rem 10px; 
   }
   
   .cart_chip .form-chips{
     display: grid;
     grid-template-columns: repeat(6, 1fr);
     grid-column-gap: 50px;
   }
   .cart_chip .chip{
     border-radius: 4pc;
   
   }
   /* flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600 */
   .modalButton .btnCover{
     display: flex;
     align-items: center;
    }
    .modalButton .btnCover button{
     margin-right: 7px;
     padding: 10px 20px;
   }
   /* text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600 */
   .modalButton .cancelBtn{
    border: 1px solid rgb(224, 224, 224);
   }
.MyCart .navigationBtn{
  display: flex;
  justify-content: space-between;
 padding-top: 1rem;
}
   .MyCart .nextBtn{
     background: var(--primaryColor);
     color: white;
     padding: .5rem 1rem;
   }
   .MyCart .backBtn{

   }

   .MyCart .formTitle{
     font-weight: bold;
     padding: 10px 0px;
     font-size: 1.3rem;
     color: rgb(73, 192, 176);
   }

   .bto-lb-required{
     color: red;
   }

   .MyCart .row label{
     padding-top: .6rem;
     color: rgb(99, 99, 99);
   }
   .MyCart .acceptTerms {
    font-size: 1rem;
    padding-top: 1rem;
    padding-bottom: .5rem;
  }
  .MyCart .acceptTerms a{
   color: var(--primary);
  }

  .form-error{
    color: red;
    font-size: .9rem;
    position: relative;
    top: -2px;
  }

  .MyCart .id-cards-upload{
    text-align: center;
  }
  .MyCart .id-cards-upload .description{
    padding: 10px 0px;
  }

  .MyCart .id_cards_wrapper{
    display: flex;
    justify-content: space-around;
    padding-bottom: 1rem;
  }
  .MyCart .id_cards_wrapper .label{
    font-weight: bold;
    font-size: 1.2rem;
  }
  .MyCart .labelAndInput input {
    background: var(--primary) !important;
    padding: 0px !important;
    color: white;
    width: 70%;
    border-radius: 4pc;
  }
  

  .MyCart .image-card{
    /* height: 120px; */
    border: 2px dotted gray;
    width: 300px;
    overflow: hidden;
    height: 200px;
    margin: 0 auto;
    margin-bottom: .5rem;
    border-radius: .5vw;
    
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .MyCart .image-card img{
    /* height: 130px; */
    /* width: auto;
    margin: 0 auto; */
    object-fit: cover;
  max-width: 100%;
  max-height: 100%;
    border-radius: .5vw;
  }
  
  /* .MyCart .card_container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  } */
  
  .MyCart .preview_image-card img{
    /* height: 130px; */
    /* width: auto;
    margin: 0 auto; */
  
    border-radius: .5vw;
  }
  
  .MyCart .preview_card_container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
 .MyCart .PreviewName{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px;
  }

  
   @media screen and (max-width: 500px) {
     .cart_chip .chip_container{
       padding: 8px;
       
     }
    
     .cart_chip .chip_container .chip_card::before{
       content: '';
       position: absolute;
       top: 0;
       left: 0;
       width: 100%;
       /* height: ; */
       
     
     }
        
   .cart_chip {
    display: grid;
    grid-template-columns: repeat(4, 1fr) !important;

   }
     .cart_chip .chip_container .chip_card .chip_content{
       padding: 0rem 5px; 
       font-size: .7rem;
     }
   
     .modalButton .btnCover button{
      margin-right: 7px;
      padding: 5px 8px;
      font-size: .8rem;
    }
    /* text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600 */
    .modalButton .cancelBtn{
     border: 1px solid rgb(224, 224, 224);
    }
    .MyCart .navigationBtn{
  
     padding-top: .6rem;
    }
       .MyCart .nextBtn{
         background: var(--primaryColor);
         color: white;
         padding: .2rem .5rem;
         font-size: .8rem;
        }
        .MyCart .backBtn{
         font-size: .8rem;
        padding: .2rem .5rem;
       }
    
       .MyCart .formTitle{
         font-weight: bold;
         
         font-size: 1rem;
         color: rgb(73, 192, 176);
       }
       .MyCart .row label{
        padding-top: .4rem;
        color: rgb(99, 99, 99);
        font-size: .8rem;
      }
      .MyCart .row .form-control{
        font-size: .8rem;
      }

      .productContainer{
        overflow-x: scroll;
      }
      .productContainer .table{
        /* overflow: scroll; */
        width: 600px;
        font-size: .8rem;
      }

     .MyCart .acceptTerms {
       font-size: .8rem;
     }

     .MyCart .id_cards_wrapper{
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }

    .MyCart .labelAndInput input {
      background: var(--primary) !important;
      padding: 0px !important;
      color: white;
  
    }

     .MyCart .image-card{
      height: 200px;
      width: 40px;
    
    }
    .MyCart .image-card img{
      /* height: 80px;
      width: 80px; */
    }
  
   }