/* eslint-disable  */
.Productilters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row wrap;
  padding: 1rem 0rem;
}
.Productilters .product_sort{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0rem;
}
.Productilters .product_sort .sortContainer{
  width: 150px;
  padding-right: 10px;
}
.Productilters .price_filters input {
  padding: 1rem;
  margin-left: 0rem;
}
.Productilters .switchesContainer {
  display: block;
  justify-content: space-evenly;
}
.Productilters .switchesContainer .switch{
  display: flex;
  align-items: center;
  padding: 7px 10px;
  margin-top: 10px;
}
.ViewAllProductInACategory {
  /* display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row wrap;
  position: relative; */
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
}

.ViewAllProductInACategory .item {
  flex: 0 1 calc(20% - 20px);
  /* padding-bottom: 2rem; */
  background: white;
  justify-content: center;
  align-items: center;
  text-align: center;
}
/* .ViewAllProductInACategory .item .info{
  padding: 10px;
  height: 100px;
  overflow: hidden;
} */
.ViewAllProductInACategory .item .title{
  padding: 10px;
  height: 60px;
  overflow: hidden;
  /* background: red; */
}
.ViewAllProductInACategory .item:hover {
  cursor: pointer;
}
.ViewAllProductInACategory .item:hover .title {
  color: var(--primaryColor);
}
.ViewAllProductInACategory .item .price {
  color: var(--warning);
  font-weight: bold;
}
.ViewAllProductInACategory .item:hover .image {
  transform: scale(0.9);
  border: 1px solid var(--primaryColor);
  background-color: white;
  display: flex;
  justify-content: center;
}
.ViewAllProductInACategory .image {
  border: 1px solid rgb(223, 223, 223);
  border-radius: 0.5vw;
  width: 160px;
  margin: 0 auto;
  height: 130px; /* 300 for large screen */
  transform-origin: top left;
  transform: scale(1);
  /* object-fit: fill; */
  object-fit: fill;
  object-position: center;
  transition: all ease-in-out 0.3s;
  position: relative;
}

.ViewAllProductInACategory .item  .buttons{
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ViewAllProductInACategory .item  .buttons .addToCart{
background: var(--primaryGradient);
padding: .2rem .7rem;
display: flex;
align-items: center;
gap: 4px;
color: white;
font-size: .8rem;
border-radius: 4pc;
transition: all ease-in-out .2s;
}
.ViewAllProductInACategory .item  .buttons .addToCart:hover{
background: var(--primaryGradientHover);

}

.searchProductComponent {
  background: rgba(158, 158, 158, 0.425);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.searchProductComponent .searchCard {
  background-color: #f4f4f4;
  padding: 10px;
  width: 50%;
  margin: 0 auto;
  border-radius: 0.5vw;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  text-align: center;
  padding: 4rem;
}
.searchProductComponent .searchCard .search {
  position: relative;
  background-color: white;
  padding: 0.5rem;
  border-radius: 5pc;
  flex: 1;
}
.searchProductComponent .searchCard .searchIcon {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translate(-50%, -50%);
}

.searchProductComponent .searchCard .searchInput {
  width: 100%;
  padding: 0.3rem;
  border: none;
  padding-left: 2rem;
  outline: none;
}
.searchProductComponent .searchCard button {
  width: 30%;
  margin: 0 auto;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .Productilters {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-flow: row wrap;
        padding: .5rem 0rem;
      }
      .Productilters .product_sort .sortContainer{
        width: auto;
        padding-right: 0px;
       
      }
      .Productilters .product_sort select{
        width: auto;
        padding-right: 10px;
        border: none;
        font-size: .7rem;
      }
      .Productilters .price_filters input {
        padding: .4rem;
        margin-left: 1rem;
        display: flex;
        font-size: .6rem;
      }
      .Productilters .price_filters .priceInput {
        width: 60px;
        margin-bottom: 5px;

      }

      .ViewAllProductInACategory {
        grid-template-columns: repeat(2, 1fr);
      }

  .ViewAllProductInACategory .item {
    flex: 0 1 calc(50% - 5px);
    padding-bottom: 1rem;
  }
  .ViewAllProductInACategory .image {
    height: 120px; /* 300 for large screen */
    object-fit: cover;
  }
  .ViewAllProductInACategory .image img {
    height: 120px; /* 300 for large screen */
    object-fit: cover;
  }

  .ViewAllProductInACategory .item .title {
    font-size: .8rem;
    text-align: center;
  }
   .Productilters .prices {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .ViewAllProductInACategory .item .price {
    font-size: .8rem;
    color: var(--primaryColor);
    text-align: center;
    font-weight: 600;
  }
/* /// HERE */
/* .ViewAllProductInACategory .item:hover .image {
  transform: scale(0.9);
  border: 1px solid var(--primaryColor);
  background-color: white;
  display: flex;
  justify-content: center;
} */


.ViewAllProductInACategory .item  .buttons{
  /* padding: 10px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* font-size: .8rem; */
}
.ViewAllProductInACategory .item  .buttons .addToCart{
/* background: var(--primaryColor);
padding: .2rem .7rem;
display: flex;
align-items: center;
gap: 4px; */
align-items: center;
color: white;
font-size: .8rem;
padding: 5px 8px;
}

/* /// HERE */



.searchProductComponent {
  background: rgba(158, 158, 158, 0.425);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.searchProductComponent .searchCard {
  background-color: #f4f4f4;
  padding: 10px;
  width: 95%;
  margin: 0 auto;
  border-radius: 0.9vw;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  text-align: center;
  padding: 3rem 1rem;
  
}
.searchProductComponent .searchCard .search {
  position: relative;
  background-color: white;
  padding: 0.5rem;
  border-radius: 5pc;
  flex: 1;
}
.searchProductComponent .searchCard .searchIcon {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translate(-50%, -50%);
}

.searchProductComponent .searchCard .searchInput {
  width: 100%;
  padding: 0.3rem;
  border: none;
  padding-left: 2rem;
  outline: none;
}
.searchProductComponent .searchCard button {
  width: 50%;
  margin: 0 auto;
  cursor: pointer;
  padding: 0;
  border-radius: 4pc;
}
  
}
