/* eslint-disable  */
.paginations {
  display: flex;
  justify-content: space-around;
  padding-bottom: 3rem;
  padding-top: 4rem;
}
.paginations .numbers {
  display: flex;
}
.paginations .numbers .number {
  padding: 1px 12px;
  margin-right: 0.3rem;
  border-radius: 4pc;
}
.paginations .numbers .number:hover {
  background: var(--primaryColorLite);
  cursor: pointer;
}
.paginations .numbers .active {
  background: var(--primaryColor);
  color: white;
}

.searchContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin: 1rem 0rem; */
  margin:  .5rem auto;
  gap: 10px;
  /* background-color: red; */
  width: 80%;
}
.searchContainer .search {
  flex: 1;
  max-width: 80%;
}
.searchContainer input {
  width: 100%;
  padding: 0.6rem 1rem;
  border-radius: 4pc;
}
.searchContainer .searchIcon {
  background-color: var(--primaryColor);
  width: 50px;
  height: 50px;
  font-size: 1rem;
  color: white;
  border-radius: 50%;
}

.ProductCard .productInfo .productImage img {
  border-radius: 0.5rem;
}
.ProductCard .productInfo .productDetailes h3 {
  font-size: 1.1rem;
  margin: 0;
  padding: 0.5rem 0rem;
}
.ProductCard .productInfo .productDetailes p {
  font-size: 0.7rem;
  width: 95%;
}
.ProductCard .productInfo .ProductPrice .price {
  color: rgb(197, 197, 197);
}
.ProductCard .productInfo .ProductPrice .priceContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ProductCard .productInfo .ProductPrice .priceContent h3 {
  font-weight: 600;
  font-size: 1.5rem;
}
.ProductCard .productInfo .ProductPrice .priceContent h3 small {
  font-size: 0.9rem;
  text-decoration: line-through;
  color: gray;
}
.ProductCard .productInfo .addToCatBtn {
  background: var(--primaryColor);
  color: var(--whiteColor);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}
.ProductCard .productInfo .addToCatBtn .icon {
  font-size: 1.3rem;
}
.ProductCard .addFavourite {
  position: absolute;
  background: rgba(244, 244, 244, 0.7960784314);
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  right: 8px;
  top: 9px;
  cursor: pointer;
}
.ProductCard .addFavourite .icon {
  font-size: 1.3rem;
}
.ProductCard .card {
  border-radius: 1rem;
  padding: 0.4rem;
  position: relative;
}

.ProductsPage .Products .productBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ProductsPage .Products .productBar .viewAll {
  background: black;
  color: white;
  padding: 1px 5px;
  border-radius: 4pc;
  font-size: 0.9rem;
  cursor: pointer;
}
.ProductsPage .featuredProduct {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
}
.ProductsPage .vehicles_section {
  margin-bottom: 1rem;
}
.ProductsPage .vehicles_section .vehicles_section_cover {
  background: var(--WhiteColor);
  /* height: 330px; */
  height: auto;
  border-radius: 5px 5px 0px 0px;
}
.ProductsPage .vehicles_section .vehicles_section_cover .header {
  background: rgba(30, 7, 238, 0.7411764706);
  color: var(--WhiteColor);
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 5px 5px 0px 0px;
}
.ProductsPage .vehicles_section .vehicles_section_cover .header h3 {
  padding-left: 10px;
}
.ProductsPage .vehicles_section .vehicles_section_cover .box_container_grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding: 20px 0px;
  gap: 10px;
}
.ProductsPage .vehicles_section .vehicles_section_cover .box_container_grid .box .imgCover {
  height: 170px;
  width: 100%;
}
.ProductsPage .vehicles_section .vehicles_section_cover .box_container_grid .box .imgCover .percent_off {
  background: #c5e7fc;
  font-family: calibri;
  font-size: 0.9rem;
  color: var(--lightGray);
  margin: 0px 0px 0px 12px;
}
.ProductsPage .vehicles_section .vehicles_section_cover .box_container_grid .box .imgCover img {
  height: 170px;
  object-fit: contain;
}
.ProductsPage .vehicles_section .vehicles_section_cover .box_container_grid .box .imgCover .imgMainCover {
  transition: ease-in-out all 0.3s;
}
.ProductsPage .vehicles_section .vehicles_section_cover .box_container_grid .box .imgCover .imgMainCover:hover {
  transform: scale(1.1);
}
.ProductsPage .vehicles_section .vehicles_section_cover .box_container_grid .descr_price {
  width: 80%;
  margin: 0 auto;
}
.ProductsPage .vehicles_section .vehicles_section_cover .box_container_grid .descr {
  color: var(--lightGray);
  font-size: 0.8rem;
  text-align: center;
}
.ProductsPage .vehicles_section .vehicles_section_cover .box_container_grid .price {
  text-align: center;
  font-weight: bold;
  font-size: 0.8rem;
  padding: 5px 0px;
  /* color: var(--grayColor); */
  
}

.AllCategoryShowProducts .productCoverList .boxList {
  display: flex;
  background: white;
  padding: 0.5rem;
  margin-bottom: 1rem;
  position: relative;
}
.AllCategoryShowProducts .productCoverList .boxList img {
  width: 150px;
  height: 100px;
}
.AllCategoryShowProducts .productCoverList .boxList .percent_off {
  background: #c5e7fc;
  font-family: calibri;
  font-size: 0.9rem;
  color: var(--lightGray);
  margin: 0px 0px 0px 12px;
  position: absolute;
  right: 0;
}
.AllCategoryShowProducts .productCoverList .boxList .title {
  font-weight: bold;
}
.AllCategoryShowProducts .productCoverList .boxList .price {
  text-align: right;
  font-weight: bold;
  color: #015f39;
  font-size: 1.2rem;
}
.AllCategoryShowProducts .searchAndGridList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
.AllCategoryShowProducts .searchAndGridList .searchBar input {
  outline: none;
  border: none;
  padding: 0.5rem;
  width: 400px;
  border-radius: 4pc;
  padding-right: 2.5rem;
}
.AllCategoryShowProducts .searchAndGridList .searchBar button {
  border: none;
  background: var(--primaryColor);
  padding: 0.5rem;
  border-radius: 4pc;
  width: 100px;
  position: relative;
  right: 30px;
}
.AllCategoryShowProducts .productCover {
  margin-bottom: 3rem;
}
.AllCategoryShowProducts .productCover .vehicles_section_cover {
  background: var(--WhiteColor);
  border-radius: 5px 5px 0px 0px;
}
.AllCategoryShowProducts .productCover .vehicles_section_cover .header {
  background: rgba(30, 7, 238, 0.7411764706);
  color: var(--WhiteColor);
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 5px 5px 0px 0px;
}
.AllCategoryShowProducts .productCover .vehicles_section_cover .header h3 {
  padding-left: 10px;
}
.AllCategoryShowProducts .productCover .vehicles_section_cover .box_container_grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding: 20px 0px;
  grid-row-gap: 6rem;
}
.AllCategoryShowProducts .productCover .vehicles_section_cover .box_container_grid .box .imgCover {
  height: 170px;
  width: 100%;
}
.AllCategoryShowProducts .productCover .vehicles_section_cover .box_container_grid .box .imgCover .percent_off {
  background: #c5e7fc;
  font-family: calibri;
  font-size: 0.9rem;
  color: var(--lightGray);
  margin: 0px 0px 0px 12px;
}
.AllCategoryShowProducts .productCover .vehicles_section_cover .box_container_grid .box .imgCover img {
  height: 170px;
}
.AllCategoryShowProducts .productCover .vehicles_section_cover .box_container_grid .box .imgCover .imgMainCover {
  transition: ease-in-out all 0.3s;
}
.AllCategoryShowProducts .productCover .vehicles_section_cover .box_container_grid .box .imgCover .imgMainCover:hover {
  transform: scale(1.2);
}
.AllCategoryShowProducts .productCover .vehicles_section_cover .box_container_grid .descr_price {
  width: 80%;
  margin: 0 auto;
}
.AllCategoryShowProducts .productCover .vehicles_section_cover .box_container_grid .descr {
  color: var(--lightGray);
  font-size: 0.8rem;
}
.AllCategoryShowProducts .productCover .vehicles_section_cover .box_container_grid .price {
  text-align: center;
  font-weight: bold;
  font-size: 0.8rem;
  padding: 5px 0px;
  color: var(--grayColor);
}

.FavouriteProducts .favouriteContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
}
.FavouriteProducts .favouriteContainer img {
  height: 150px;
}

.productShowcaseWrapper{
  display: grid;
  grid-template-columns: 19% 60% 20%;
  grid-gap: 10px;
  margin-bottom: 10px;
}
.productShowcaseWrapper .sideOne{
  background: white;
  padding: 5px;
  border-radius: 5px;
}
.productShowcaseWrapper .sideOne .header{
  font-weight: bold;
  margin-bottom: 10px;
}
.productShowcaseWrapper .sideOne hr{
 color: rgb(185, 185, 185);
}
.productShowcaseWrapper .sideOne ul li{
  margin: 8px 0px;
  cursor: pointer;
  font-size: .8rem;
}
.productShowcaseWrapper .sideOne ul li:hover{
  color: #015f39;
  border-bottom: .2px dotted rgb(175, 175, 175);
}
.productShowcaseWrapper .sideThree{
  display: block;
  height: 100%;
  position: relative;
  
}
.productShowcaseWrapper .sideThree .info{
  background-color: white;
  height: 50%;
  padding: 5px;
  /* margin-bottom: 10px; */
}
.productShowcaseWrapper .sideThree .info .item{
  display: flex;
  align-items: center;
  gap: 10px;
  padding-top: 15px;
}
.productShowcaseWrapper .sideThree .info .item .icon{
  border: #015f39 solid 2px;
  border-radius: 50%;
}
.productShowcaseWrapper .sideThree .info .item .content{
 font-size: .7rem;
}

.productShowcaseWrapper .sideThree .smallBanner{
  
  height: 50%;
  width: 100%;
  border-radius: 10px;
 
}
.productShowcaseWrapper .sideThree .smallBanner img{
  border-radius: 10px;
 max-height: 100%;
 max-width: 100%;
 height: 50%;
 object-fit: cover;
 position: absolute;
 object-position: left;
 bottom: 0px;

}

.ProductSlider {
  margin-bottom: 2rem;
}
.ProductSlider .slider {
  height: 350px;
  /* height: 350px; */
  /* border: 2px solid red; */
  width: 100%;
  margin: 0;
  padding: 0;
}
.ProductSlider .slider img{
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}
.ProductSlider .carousel .slide .legend {
  background: transparent;
  font-size: 2rem;
  opacity: 1;
  margin-bottom: 0px;
}

.ProductSlider .carousel.carousel-slider .control-arrow{
  background: transparent !important;
}

.MyCart .cartProducts:nth-child(even) {
  background: rgb(255, 251, 251);
}
.MyCart .cartProducts {
  display: grid;
  grid-template-columns: 15% 85%;
  margin-top: 1rem;
  padding: 5px;
}
.MyCart .cartProducts .productImage {
  flex-grow: 1;
  height: 100px;
  /* width: 50%; */
  overflow: hidden;
}
.MyCart .cartProducts .productImage img {
  max-width: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 100%;
  object-fit: cover;
}
.MyCart .cartProducts .TitlePrice {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.MyCart .cartProducts .TitlePrice .productTitle {
  color: rgb(156, 156, 156);
}
.MyCart .cartProducts .TitlePrice .productTitle h2 {
  font-size: 1.3rem;
}
.MyCart .cartProducts .TitlePrice .ProductPrice {
  font-size: 1rem;
  font-weight: 500;
}
.MyCart .cartProducts .stock {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.MyCart .cartProducts .stock .PriceCance {
  text-decoration: line-through;
  color: gray;
}
.MyCart .cartProducts .stock .inStock {
  color: var(--success);
}
.MyCart .cartProducts .stock > * {
  flex-basis: 1;
  flex: 1;
}
.MyCart .cartProducts .stock select {
  box-shadow: 1px 24px 85px -38px rgba(0, 0, 0, 0.658);
}
.MyCart .cartProducts .FilterAndDelete {
  display: flex;
  justify-content: space-between;
}
.MyCart .cartProducts .FilterAndDelete .sandAndDeleteButtons .btn {
  color: gray;
  font-size: 0.8rem;
}
.MyCart .cartProducts .FilterAndDelete .sandAndDeleteButtons .icon {
  font-size: 1rem;
}
.MyCart .cartProducts .quantityFilters {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* width: 40%; */
  /* gap: 50px; */
}
.MyCart .cartProducts .quantityFilters .sizes select {
  padding: 0.3rem 0.7rem;
  margin-right: 0.5rem;
  border: none;
  border-radius: 0.4vw;
  width: auto;
}
.MyCart .cartProducts .sizesWrapper, .MyCart .cartProducts .colorsWrapper, .MyCart .cartProducts .quantityWrapper {
display: flex;
align-items: center;
gap: 10px;

}
.MyCart .cartProducts .NA {
  color: rgb(165, 165, 165);
  font-size: .8rem;
}
.MyCart .cartProducts .quantityFilters .colors select {
  padding: 0.3rem 0.7rem;
  margin-right: 0.5rem;
  border: none;
  border-radius: 0.4vw;
}
.MyCart .cartProducts .quantityFilters .quantity {
  padding: 0.3rem 0.7rem;
}
.MyCart .cartProducts .quantityFilters .quantity .minus {
  background: white;
  padding: 0.3rem 0.7rem;
}
.MyCart .cartProducts .quantityFilters .quantity .plus {
  background: white;
  padding: 0.3rem 0.7rem;
}
.MyCart .cartProducts .sandAndDeleteButtons {
  display: flex;
}
.MyCart .TotalProducts {
  display: flex;
  justify-content: flex-end;
}
.MyCart .TotalProducts .item {
  width: 30%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.MyCart .TotalProducts .item .totalText {
  font-size: 1.7rem;
}
.MyCart .TotalProducts .item .totalPrice {
  font-size: 1.7rem;
  font-weight: bold;
}
.MyCart .confirmBtn {
  display: flex;
  justify-content: flex-end;
}

.ProductDetailed .backBtn {
  background: gray;
  padding: 0rem 0.5rem;
  border-radius: 4pc;
  color: white;
}
.ProductDetailed .imageAndInfo {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.ProductDetailed .imageAndInfo .imageSection {
  background: white;
}
.ProductDetailed .imageAndInfo .imageSection .carousel.carousel-slider {
  height: 400px;
  /* width: 80%; */
}
.ProductDetailed .imageAndInfo .imageSection .carousel.carousel-slider .imgCover{
  height: 400px;
  width: 80%;
}
.ProductDetailed .imageAndInfo .imageSection .carousel.carousel-slider img {
  /* height: 400px; */
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
}
.ProductDetailed .imageAndInfo .imageSection .carousel .slide .legend {
  display: none;
}
.ProductDetailed .imageAndInfo .imageSection .carousel .thumbs-wrapper {
  margin: 0px;
}
.ProductDetailed .imageAndInfo .imageSection .carousel .thumbs-wrapper img {
  height: 50px;
  object-fit: cover;
}
.ProductDetailed .imageAndInfo .imageSection .slider img {
  height: 100%;
}
.ProductDetailed .imageAndInfo .smallInfoSection {
  padding: 1rem;
}
.ProductDetailed .imageAndInfo .smallInfoSection .productTitle {
  font-weight: 500;
  color: rgb(83, 83, 83);
}
.ProductDetailed .imageAndInfo .smallInfoSection .small_description {
  background: var(--whiteColor);
  padding: 0.5rem;
  border-radius: 0.4vw;
}
.ProductDetailed .imageAndInfo .smallInfoSection .stockAvialable {
  color: var(--success);
}
.ProductDetailed .imageAndInfo .smallInfoSection .outOfStock {
  color: var(--warning);
}
.ProductDetailed .imageAndInfo .smallInfoSection .price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}
.ProductDetailed .imageAndInfo .smallInfoSection .price .currentPrice {
  font-size: 2rem;
  margin-right: 1rem;
  font-weight: 500;
}
.ProductDetailed .imageAndInfo .smallInfoSection .price .oldPrice {
  text-decoration: line-through;
  color: rgb(170, 170, 170);
}
.ProductDetailed .imageAndInfo .smallInfoSection .price .priceDuration select {
  padding: 15px;
}
.ProductDetailed .imageAndInfo .smallInfoSection .sizeFilters .title {
  padding-top: 0.8rem;
}
.ProductDetailed .imageAndInfo .smallInfoSection .sizeFilters .sizes {
  display: flex;
  align-items: center;
  margin: 1rem 0rem;
}
.ProductDetailed .imageAndInfo .smallInfoSection .sizeFilters .sizes div {
  display: flex;
  background: white;
  margin-right: 1rem;
  border: 2px solid var(--primaryColorLite);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}
.ProductDetailed .imageAndInfo .smallInfoSection .colorFilters,
.ProductDetailed .imageAndInfo .smallInfoSection .sizeFilters
{
  padding: 1rem 0rem;
}
.ProductDetailed .imageAndInfo .smallInfoSection .colorFilters .title {
  padding-top: 0.8rem;
}
.ProductDetailed .imageAndInfo .smallInfoSection .colorFilters .colors {
  display: flex;
  align-items: center;
  margin: 1rem 0rem;
}
.ProductDetailed .imageAndInfo .smallInfoSection .colorFilters .colors div {
  display: flex;
  background: white;
  margin-right: 1rem;
  border: none;
  width: 60px;
  height: 40px;
  border-radius: 0.5vw;
  align-items: center;
  justify-content: center;
}
.ProductDetailed .imageAndInfo .smallInfoSection .quantity input {
  width: 20%;
}
.ProductDetailed .imageAndInfo .smallInfoSection .cartBtn {
  text-align: end;
  margin-top: 0.5rem;
}
.ProductDetailed .imageAndInfo .smallInfoSection .cartBtn button {
  background: linear-gradient(to bottom, #01a94b 20%, #015f39 100%);
  border: none;
  padding: 1rem;
  width: 30%;
  color: white;
}
.ProductDetailed .imageAndInfo .smallInfoSection .cartBtn .favourite {
  background: rgb(39, 39, 39);
  margin-right: 1rem;
  border: none;
  padding: 1rem 0rem;
  width: 30%;
  color: white;
}
.ProductDetailed .productImages img {
  width: 60%;
  margin-bottom: 1rem;
  border-radius: 0.6rem;
}
.ProductDetailed .detailDescription {
  font-size: 0.8rem;
  /* margin-left: 1rem; */
  padding: 2rem 2rem;
  font-size: 1rem;
}

@media screen and (max-width: 500px) {
  .paginations {
    display: flex;
    justify-content: space-around;
    margin-bottom: 3rem;
    margin-top: 4rem;
    width: 300px;
    max-width: 300px;
    margin: 0 auto;
  }
  .paginations .numbers {
    display: flex;
    width: 50%;
    overflow-x: scroll;
  }
  .paginations .numbers .number {
    padding: 1px 12px;
    margin-right: 0.3rem;
    border-radius: 4pc;
  }
  .paginations .numbers .number:hover {
    background: var(--primaryColorLite);
    cursor: pointer;
  }
  .paginations .numbers .active {
    background: var(--primaryColor);
    color: white;
  }
  .searchContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1rem 0rem;
    margin:  0 auto;
  }
  .searchContainer .search {
    flex: 1;
    max-width: 80%;
   
  }
  .searchContainer input {
    width: 100%;
    padding: 0.5rem 1rem;
    border-radius: 4pc;
  }
  .searchContainer .searchIcon {
    background-color: var(--primaryColor);
    width: 50px;
    height: 50px;
    font-size: 1rem;
    color: white;
    border-radius: 50%;
  }
  .FavouriteProducts .favouriteContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
  }
  .vehicles_section {
    margin-bottom: 1rem;
  }
  .vehicles_section .vehicles_section_cover {
    background: var(--WhiteColor);
    height: auto !important;
    border-radius: 5px 5px 0px 0px;
  }
  .vehicles_section .vehicles_section_cover .header {
    background: rgba(30, 7, 238, 0.7411764706);
    color: var(--WhiteColor);
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 5px 5px 0px 0px;
  }
  .vehicles_section .vehicles_section_cover .header h3 {
    padding-left: 10px;
  }
  .vehicles_section .vehicles_section_cover .box_container_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
    padding: 20px 0px;
  }
  .vehicles_section .vehicles_section_cover .box_container_grid .box .imgCover {
    height: auto !important;
    width: 100%;
  }
  .vehicles_section .vehicles_section_cover .box_container_grid .box .imgCover .percent_off {
    background: #c5e7fc;
    font-family: calibri;
    font-size: 0.9rem;
    color: var(--lightGray);
    margin: 0px 0px 0px 12px;
  }
  .vehicles_section .vehicles_section_cover .box_container_grid .box .imgCover img {
    height: 100px !important;
    object-fit: contain;
  }
  .vehicles_section .vehicles_section_cover .box_container_grid .box .imgCover .imgMainCover {
    transition: ease-in-out all 0.3s;
  }
  .vehicles_section .vehicles_section_cover .box_container_grid .box .imgCover .imgMainCover:hover {
    transform: scale(1.2);
  }
  .vehicles_section .vehicles_section_cover .box_container_grid .descr_price {
    width: 80%;
    margin: 0 auto;
  }
  .vehicles_section .vehicles_section_cover .box_container_grid .descr {
    color: var(--lightGray);
    font-size: 0.8rem;
  }
  .vehicles_section .vehicles_section_cover .box_container_grid .price {
    text-align: center;
    font-weight: bold;
    font-size: 0.8rem;
    padding: 5px 0px;
    color: var(--grayColor);
  }

  .productShowcaseWrapper{
    display: block;
  }
  .productShowcaseWrapper .sideOne{
    display: none;
  }
  .productShowcaseWrapper .sideThree{
    display: none;
  }

  .ProductSlider{
    margin-bottom: 0rem;
    margin-top: 1rem;
  }
  .ProductSlider .slider { 
    height: 300px;
  }
 
  .ProductSlider .carousel .slide .legend {
    background: transparent;
    font-size: 1rem;
    opacity: 1;
  }
  .ProductDetailed {
    width: 100%;
    overflow: none;
  }
  .ProductDetailed .imageAndInfo {
    display: block;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
  }
  .ProductDetailed .imageAndInfo .imageSection {
    background: white;
  }
  .ProductDetailed .imageAndInfo .imageSection .carousel.carousel-slider {
    height: 250px;
  }
  .ProductDetailed .imageAndInfo .imageSection .carousel.carousel-slider img {
    height: 250px;
  }
  .ProductDetailed .imageAndInfo .imageSection .carousel .slide .legend {
    display: none;
  }
  .ProductDetailed .imageAndInfo .imageSection .carousel .thumbs-wrapper {
    margin: 0px;
  }
  .ProductDetailed .imageAndInfo .imageSection .carousel .thumbs-wrapper img {
    height: 50px;
  }
  .ProductDetailed .imageAndInfo .imageSection .slider img {
    height: 100%;
  }
  .ProductDetailed .imageAndInfo .smallInfoSection {
    padding: 1rem;
  }
  .ProductDetailed .imageAndInfo .smallInfoSection .productTitle {
    font-weight: 500;
    color: black;
    font-size: 1rem;
    font-weight: 600;
  }
  .ProductDetailed .imageAndInfo .smallInfoSection .small_description {
    background: var(--whiteColor);
    padding: 0.5rem;
    border-radius: 0.4vw;
    font-size: 0.9rem;
  }
  .ProductDetailed .imageAndInfo .smallInfoSection .stockAvialable {
    color: var(--success);
  }
  .ProductDetailed .imageAndInfo .smallInfoSection .outOfStock {
    color: var(--warning);
  }
  .ProductDetailed .imageAndInfo .smallInfoSection .price {
    display: flex;
    align-items: center;
  }
  .ProductDetailed .imageAndInfo .smallInfoSection .price .currentPrice {
    font-size: 2rem;
    margin-right: 1rem;
    font-weight: 500;
  }
  .ProductDetailed .imageAndInfo .smallInfoSection .price .oldPrice {
    text-decoration: line-through;
    color: rgb(170, 170, 170);
  }
  .ProductDetailed .imageAndInfo .smallInfoSection .sizeFilters .title {
    padding-top: 0.8rem;
  }
  .ProductDetailed .imageAndInfo .smallInfoSection .sizeFilters .sizes {
    display: flex;
    align-items: center;
    margin: 0.8rem 0rem;
  }
  .ProductDetailed .imageAndInfo .smallInfoSection .sizeFilters .sizes div {
    font-size: 0.8rem;
    display: flex;
    background: white;
    margin-right: 0.3rem;
    border: 1px solid rgba(204, 204, 204, 0.925);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
  }
  .ProductDetailed .imageAndInfo .smallInfoSection .colorFilters .title {
    padding-top: 0.8rem;
  }
  .ProductDetailed .imageAndInfo .smallInfoSection .colorFilters .colors {
    display: flex;
    align-items: center;
    margin: 1rem 0rem;
  }
  .ProductDetailed .imageAndInfo .smallInfoSection .colorFilters .colors div {
    display: flex;
    background: white;
    margin-right: 1rem;
    border: none;
    width: 34px;
    height: 23px;
    border-radius: 0.5vw;
    align-items: center;
    justify-content: center;
  }
  .ProductDetailed .imageAndInfo .smallInfoSection .quantity input {
    width: 20%;
  }
  .ProductDetailed .imageAndInfo .smallInfoSection .cartBtn {
    text-align: end;
    margin-top: 0.5rem;
  }
  .ProductDetailed .imageAndInfo .smallInfoSection .cartBtn button {
    background: linear-gradient(to bottom, #01a94b 20%, #015f39 100%);
    font-size: 0.7rem;
    border: none;
    padding: 0.5rem;
    width: auto;
    color: white;
  }
  .ProductDetailed .imageAndInfo .smallInfoSection .cartBtn .favourite {
    background: rgb(39, 39, 39);
    margin-right: 1rem;
    border: none;
    padding: 0.5rem 0.3rem;
    width: auto;
    color: white;
  }
  .ProductDetailed .detailDescription {
    font-size: 0.8rem;
  
  }
  .ProductDetailed .productImages img {
    width: 100%;
    margin-bottom: 1rem;
    border-radius: 0.6rem;
  }
  .AllCategoryShowProducts .boxList {
    display: flex;
    background: white;
    padding: 0.5rem;
    margin-bottom: 1rem;
    position: relative;
  }
  .AllCategoryShowProducts .boxList img {
    width: 100px !important;
    height: 60px !important;
  }
  .AllCategoryShowProducts .boxList .percent_off {
    background: #c5e7fc;
    font-family: calibri;
    font-size: 0.9rem;
    color: var(--lightGray);
    margin: 0px 0px 0px 12px;
    position: absolute;
    left: 0;
    width: 10%;
    right: none !important;
  }
  .AllCategoryShowProducts .boxList .title {
    font-weight: bold;
  }
  .AllCategoryShowProducts .boxList .descr {
    font-size: 0.8rem;
  }
  .AllCategoryShowProducts .boxList .price {
    text-align: right;
    font-weight: bold;
    color: #015f39;
  }
  .AllCategoryShowProducts .searchAndGridList {
    display: block;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  .AllCategoryShowProducts .searchAndGridList .searchBar input {
    outline: none;
    border: none;
    padding: 0.5rem;
    width: auto;
    border-radius: 4pc;
    padding-right: 2.5rem;
  }
  .AllCategoryShowProducts .searchAndGridList .searchBar button {
    border: none;
    background: var(--primaryColor);
    padding: 0.5rem;
    border-radius: 4pc;
    width: auto;
    position: none;
    right: 30px;
  }
  .AllCategoryShowProducts .searchAndGridList .listNdGridIcons {
    text-align: right;
    margin-top: 1rem;
  }
  .AllCategoryShowProducts .productCover {
    margin-bottom: 3rem;
  }
  .AllCategoryShowProducts .productCover .vehicles_section_cover {
    background: var(--WhiteColor);
    border-radius: 5px 5px 0px 0px;
  }
  .AllCategoryShowProducts .productCover .vehicles_section_cover .header {
    background: rgba(30, 7, 238, 0.7411764706);
    color: var(--WhiteColor);
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 5px 5px 0px 0px;
  }
  .AllCategoryShowProducts .productCover .vehicles_section_cover .header h3 {
    padding-left: 10px;
  }
  .AllCategoryShowProducts .productCover .vehicles_section_cover .box_container_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
    padding: 20px 0px;
    grid-row-gap: 6rem;
  }
  .AllCategoryShowProducts .productCover .vehicles_section_cover .box_container_grid .box .imgCover {
    height: 100px;
    width: 100%;
  }
  .AllCategoryShowProducts .productCover .vehicles_section_cover .box_container_grid .box .imgCover .percent_off {
    background: #c5e7fc;
    font-family: calibri;
    font-size: 0.9rem;
    color: var(--lightGray);
    margin: 0px 0px 0px 12px;
  }
  .AllCategoryShowProducts .productCover .vehicles_section_cover .box_container_grid .box .imgCover img {
    height: 100px;
  }
  .AllCategoryShowProducts .productCover .vehicles_section_cover .box_container_grid .box .imgCover .imgMainCover {
    transition: ease-in-out all 0.3s;
  }
  .AllCategoryShowProducts .productCover .vehicles_section_cover .box_container_grid .box .imgCover .imgMainCover:hover {
    transform: scale(1.2);
  }
  .AllCategoryShowProducts .productCover .vehicles_section_cover .box_container_grid .descr_price {
    width: 80%;
    margin: 0 auto;
  }
  .AllCategoryShowProducts .productCover .vehicles_section_cover .box_container_grid .descr {
    color: var(--lightGray);
    font-size: 0.8rem;
  }
  .AllCategoryShowProducts .productCover .vehicles_section_cover .box_container_grid .price {
    text-align: center;
    font-weight: bold;
    font-size: 0.8rem;
    padding: 5px 0px;
    color: var(--grayColor);
  }
  .MyCart .cartProducts {
    display: grid;
    grid-template-columns: 20% 80%;
  }
  .MyCart .cartProducts .productImage {
    flex-grow: 1;
  }
  .MyCart .cartProducts .productImage img {
    height: 50px;
    width: 100%;
  }
  .MyCart .cartProducts .TitlePrice .productTitle {
    color: rgb(156, 156, 156);
  }
  .MyCart .cartProducts .TitlePrice .productTitle h2 {
    font-size: 1rem;
  }
  .MyCart .cartProducts .TitlePrice .ProductPrice {
    font-size: 0.9rem;
    font-weight: 500;
  }
  .MyCart .cartProducts .stock {
    margin-bottom: 1rem;
    font-size: 0.8rem;
  }
  .MyCart .cartProducts .stock .PriceCance {
    text-decoration: line-through;
    color: gray;
    font-size: 0.8rem;
  }
  .MyCart .cartProducts .stock .inStock {
    color: var(--success);
  }
  .MyCart .cartProducts .FilterAndDelete {
    display: flex;
    justify-content: space-between;
  }
  .MyCart .cartProducts .FilterAndDelete .sandAndDeleteButtons .btn {
    color: gray;
    font-size: 0.7rem;
  }
  .MyCart .cartProducts .FilterAndDelete .sandAndDeleteButtons .icon {
    font-size: 1rem;
  }
  .MyCart .cartProducts .quantityFilters {
    display: flex;
  }
  .MyCart .cartProducts .quantityFilters .sizes select {
    padding: 0.3rem 0rem;
    margin-right: 0.5rem;
    border: none;
    border-radius: 0.4vw;
    font-size: 0.7rem;
  }
  .MyCart .cartProducts .quantityFilters .colors select {
    padding: 0.3rem 0rem;
    margin-right: 0.5rem;
    border: none;
    border-radius: 0.4vw;
    font-size: 0.7rem;
  }
  .MyCart .cartProducts .quantityFilters .quantity {
    padding: 0.3rem 0rem;
    font-size: 0.8rem;
  }
  .MyCart .cartProducts .quantityFilters .quantity .minus {
    background: white;
    padding: 0.3rem 0.4rem;
  }
  .MyCart .cartProducts .quantityFilters .quantity .plus {
    background: white;
    padding: 0.3rem 0.4rem;
  }
  .MyCart .cartProducts .sandAndDeleteButtons {
    display: flex;
  }
  .MyCart .TotalProducts {
    display: flex;
    justify-content: flex-end;
    margin-top: 0.5rem;
  }
  .MyCart .TotalProducts .item {
    width: 40%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .MyCart .TotalProducts .item .totalText {
    font-size: 1rem;
  }
  .MyCart .TotalProducts .item .totalPrice {
    font-size: 1rem;
    font-weight: bold;
  }
  .MyCart .phoneIcon {
    position: relative;
    top: -30px;
    margin-left: 1rem;
  }
  .MyCart .confirmBtn {
    display: flex;
    justify-content: flex-end;
    font-size: 0.8rem;
  }
}/*# sourceMappingURL=Shopping.css.map */