/* eslint-disable  */
.requestOrderList {
    display: flex;
    align-items: center;
    background: white;
    margin: 10px 0px;
    box-shadow: var(--shadow);
    justify-content: space-between;
    padding-right: 5px;
}

.requestOrderList .imageNdText {
    display: flex;
    align-items: center;
    gap: 10px;
}

.requestOrderList .imageCover {
    width: 80px;
    height: 80px;
}

.requestOrderList .imageCover img {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
}

.requestOrderList .cancel_btn button {
    background: var(--danger);
    color: white;
    padding: 4px 8px;
    border-radius: 4pc;
    font-size: .8rem;

}

.requestOrderList .cancel_btn button:hover {
    background: var(--dangerHover);


}



.RequestOrder-wrapper {
    width: 70%;
    margin: 0 auto;
    background: white;
    padding: 1rem;
    margin-top: 3rem;
}

.RequestOrder-wrapper .title {
    text-align: center;
    font-weight: bold;
    font-size: 2rem;
}

.RequestOrder-wrapper .description {
    margin: 20px 0px;
}

.RequestOrder-wrapper .imagesWrapper {
    display: flex;
    gap: 15px;
    margin-bottom: 7px;
}

.RequestOrder-wrapper .imagesContainer .imgCoveer {
    width: 90px;
    height: 0px;
}

.RequestOrder-wrapper .imagesContainer img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}


/* MOBILE STYLES */
@media screen and (max-width: 500px) {
    .requestOrderList {
        display: grid;
        grid-template-columns: 80% 20%;

    }

    .requestOrderList .imageNdText {
        font-size: .7rem;
    }

    .requestOrderList .imageCover {
        width: 40px;
        height: 40px;
    }

    .requestOrderList .cancel_btn button {
        padding: 4px 4px;
        font-size: .5rem;
        font-weight: bold;

    }



    .RequestOrder-wrapper {
        width: 90%;
    }

    .RequestOrder-wrapper .title {

        font-size: 1.4rem;
        margin-bottom: 10px;
    }

    .RequestOrder-wrapper .description {
        margin: 20px 0px;
    }

    .RequestOrder-wrapper .imagesWrapper {
        display: flex;
        gap: 15px;
        margin-bottom: 7px;
    }

    .RequestOrder-wrapper .imagesContainer .imgCoveer {
        width: 40px;
        height: 40px;
    }

    .RequestOrder-wrapper .imagesContainer img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
    }

}