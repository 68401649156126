/* eslint-disable  */
.loginPage{
    display: grid;
    grid-template-columns: 25% 75%;
    justify-content: center;
    align-items: center;
    height: 100vh;
  
    .section_one{
        .overlay{
            padding: 1rem;
            background: rgba(255, 255, 255, 0.884);
            height: 100vh;
            display: flex;
            justify-items: center;
            flex-direction: column;
            justify-content: center;
            
            .title{
                padding-bottom: 2rem;
                color: var(--primaryColor);
            }
        }
    }
     .email-form-group{
        margin-bottom: 1rem;
    }
    .password-form-group{
        margin-bottom: 1rem;
    }
    .forgot_password{
        text-align: end;
    }
    .trigger_register{
        padding-top: 2rem;
        text-align: center;
        a{
            text-decoration: none;
        }
    }

.section_two {
    border-radius: 0rem 0rem 0rem 3rem;
    .overlay{
        background: rgba(0, 89, 255, 0.692);
        height: 100vh;
        display: flex;
        justify-items: center;
        flex-direction: column;
        justify-content: center;
        color: white;
        border-radius: 0rem 0rem 0rem 3rem;
        .content{
            padding: 2rem;
            h1{
                font-size: 4rem;
                font-weight: bold;
                width: 80%;
                padding-bottom: 2rem;
            }
            p{
                width: 50%;
            }
        }
     }
}
}


// SIGN UP DESKTOP
.registerPage{
    display: grid;
    grid-template-columns: 25% 75%;
    justify-content: center;
    align-items: center;
    height: 100vh;
    .section_one{
        .overlay{
            padding: 1rem;
            background: rgba(255, 255, 255, 0.884);
            height: 100vh;
            display: flex;
            justify-items: center;
            flex-direction: column;
            justify-content: center;
            .title{
                padding-bottom: 2rem;
                color: var(--primaryColor);
                
            }
        }
    }
     .email-form-group{
        margin-bottom: 1rem;
    }
    .password-form-group{
        margin-bottom: 1rem;
    }
    .forgot_password{
        text-align: end;
    }
    .trigger_register{
        padding-top: 2rem;
        text-align: center;
        a{
            text-decoration: none;
        }
    }

.section_two {
    border-radius: 0rem 0rem 0rem 3rem;
    .overlay{
        background: rgba(0, 89, 255, 0.692);
        height: 100vh;
        display: flex;
        justify-items: center;
        flex-direction: column;
        justify-content: center;
        color: white;
        border-radius: 0rem 0rem 0rem 3rem;
        .content{
            padding: 2rem;
            h1{
                font-size: 4rem;
                font-weight: bold;
                width: 80%;
                padding-bottom: 2rem;
            }
            p{
                width: 50%;
            }
        }
     }
}
}

.ResetPassword{
    height: 100vh;
    background: url('/public/images/dev/nurse-float.jpeg') no-repeat center center/cover;
    .overlay{
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: rgba(255, 255, 255, 0.863);
        .form{
            width: 40%;
            input{
                margin-bottom: 1rem;
            }
        }
    }
}


@media screen and (max-width: 500px) {
    .loginPage{
        display: block;
        grid-template-columns: 25% 75%;
        justify-content: center;
        align-items: center;
        height: 100vh;
        .section_one{
            height: calc(70vh);
            .overlay{
                padding: 1rem;
                background: rgba(255, 255, 255, 0.884);
                height: calc(70vh);
                display: flex;
                justify-items: center;
                flex-direction: column;
                justify-content: center;
                .title{
                    padding-bottom: 1rem;
                    color: var(--primaryColor);
                    font-size: 2rem;
                }
            }
        }
         .email-form-group{
            margin-bottom: 1rem;
        }
        .password-form-group{
            margin-bottom: 1rem;
        }
        .forgot_password{
            text-align: end;
        }
        .trigger_register{
            padding-top: 1rem;
            text-align: center;
            a{
                text-decoration: none;
            }
        }
    
    .section_two {
        border-radius: 3rem 0rem 0rem 0rem;
        height: calc(30vh);
        .overlay{
            background: rgba(1, 110, 101, 0.692);
            height: calc(30vh);
            display: flex;
            justify-items: center;
            flex-direction: column;
            justify-content: center;
            color: white;
            border-radius: 3rem 0rem 0rem 0rem;
            .content{
                // padding: 1rem;
                h1{
                    font-size: 1.5rem;
                    font-weight: bold;
                    width: 100%;
                    padding-bottom: 1rem;
                }
                p{
                    width: 100%;
                }
            }
         }
    }
    }

    .ResetPassword{
      
        .overlay{
            height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background: rgba(255, 255, 255, 0.863);
            .form{
                width: 80%;
                input{
                    margin-bottom: 1rem;
                }
            }
        }
    }
}