.NotFound404{
    height: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    background: white;

}
.NotFound404 .imgCover{
    width: 50%;
}
.NotFound404 p{
    text-align: center;
    font-size: 20px;
}
.NotFound404 .btnCover{
display: flex;
justify-content: center;
align-items: center;
padding-top: 1rem;
}

@media screen and (max-width: 500px){
    .NotFound404{
       
        height: 50vh;
        display: flex;
       
    
    }

    .NotFound404 p{
        padding-top: 2rem;
        text-align: center;
        font-size: 15px;
    }
}