.VerifyEmailSuccessWrapper{
    display: grid;
    grid-template-columns: 20% 80%;
    height: 100vh;
}

.VerifyEmailSuccessWrapper .side-1 .overlay{
    background: linear-gradient(
        to bottom,
        #01a94af1 10%,
        var(--primaryColorAcent) 100%
      );
      /* height: 100vh; */
    height: 100%;
}



.VerifyEmailSuccessWrapper .side-2 .overlay{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.945);
}
.VerifyEmailSuccessWrapper img{
    width: 100px;
    margin-bottom: 2rem;
    
}
.VerifyEmailSuccessWrapper h2{
    font-weight: bold;
    margin-bottom: .5rem;
}
.VerifyEmailSuccessWrapper button{
    background-color: var(--primaryColor);
    color: white;
    margin-top: 1rem;
}
.VerifyEmailSuccessWrapper button:hover{
    background-color: var(--primaryColorLite);
    color: white;
}

@media screen and (max-width:500px) {
    .VerifyEmailSuccessWrapper img{
        width: 80px;
        margin-bottom: 2rem;
        
    }
}