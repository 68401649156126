
/* owl-prev */
/* .owl-carousel .owl-dots.disabled */
.owl-carousel .owl-nav.disabled {
    display: block !important;
}
/* .owl-nav{
 position: absolute;
 top: 50%;
 width: 100%;
}
.owl-nav .owl-prev{
  
    left: 0;
}
.owl-nav .owl-next{
  
    right: 0;
} */
.ProductsPage .featuredSection {
    margin-bottom: 10px;
}
.ProductsPage .featuredSection .header {
  background: white;
  background: linear-gradient(to bottom, rgb(0, 103, 221) 20%, #01325f 100%);
  color: white;
  padding: 0rem 0.5rem;
  padding: 8px 10px;
}
.ProductsPage .featuredSection .topBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.ProductsPage .featuredSection #owl-carousel {
    padding: 5px;

}
.ProductsPage .featuredSection #owl-carousel {
    position: relative;
}
.ProductsPage .featuredSection #owl-carousel .itemContainer {
    background: white;
    padding-top: 15px;
    
}
.ProductsPage .featuredSection #owl-carousel .itemContainer .imgCover {
    /* height: 100px; */
    width: 180px;
    height: 100px;
    overflow: hidden;

    margin: 0 auto;
}
.ProductsPage .featuredSection #owl-carousel .itemContainer .imgCover .image{
    /* height: 100px; */
    border-radius: 15px;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}
.ProductsPage .featuredSection #owl-carousel .itemContainer  .info{
    padding: 10px;
    display: flex;
    flex-direction: column;
    padding: 20px 20px;
    
    align-items: center;
}
.ProductsPage .featuredSection #owl-carousel .itemContainer  .buttons{
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.ProductsPage .featuredSection #owl-carousel .itemContainer  .buttons .addToCart{
background: var(--primaryColor);
padding: .2rem .7rem;
display: flex;
align-items: center;
gap: 4px;
color: white;
}

.ProductsPage .featuredSection #owl-carousel .itemContainer .short_decription{
    font-weight: bold;
    font-size: .8rem;
    color: gray;
    /* background-color: red; */
    text-align: center;
    height:50px;
    overflow-y: hidden;
}
.ProductsPage .featuredSection #owl-carousel .itemContainer .new_price{
color: var(--primary);
font-weight: bold;
font-size: .9rem;
}
.ProductsPage .featuredSection #owl-carousel .itemContainer .old_price{
    text-decoration: line-through;
    text-decoration-color: rgb(255, 135, 87);
    color:  rgb(255, 135, 87);
    font-weight: bold;
font-size: .9rem;
}



.imgMainCover{
    /* border: 2px solid red; */
    width: 150px;
    height: 100px;
    overflow: hidden;
    margin:  0 auto;
}
.imgMainCover img{
   object-fit: cover;
   max-height: 100%;
   max-width: 100%;
}
@media screen  and (max-width: 500px){
    .ProductsPage .featuredSection .header {
        
        padding: 0rem 0.5rem;
        padding: 8px 10px;
      }
      /* .ProductsPage .featuredSection .topBar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      } */
      /* .ProductsPage .featuredSection #owl-carousel {
          padding: 5px;
      
      } */
      /* .ProductsPage .featuredSection #owl-carousel {
          position: relative;
      } */
      .ProductsPage .featuredSection #owl-carousel .itemContainer {
          background: white;
          padding-top: 15px;
          
      }
      .ProductsPage .featuredSection #owl-carousel .itemContainer .imgCover {
          height: 100px;
          width: 80%;
          margin: 0 auto;
      }
      .ProductsPage .featuredSection #owl-carousel .itemContainer .imgCover .image{
          height: 100px;
          border-radius: 15px;
      }
      .ProductsPage .featuredSection #owl-carousel .itemContainer  .info{
          padding: 10px;
          display: flex;
          flex-direction: column;
          padding: 20px 10px;
          
          align-items: center;
      }
      .ProductsPage .featuredSection #owl-carousel .itemContainer  .buttons{
          padding: 5px;
          display: flex;
          justify-content: space-between;
          align-items: center;
      }
      .ProductsPage .featuredSection #owl-carousel .itemContainer  .buttons .addToCart{
      background: var(--primaryColor);
      padding: .2rem .7rem;
      display: flex;
      align-items: center;
      gap: 4px;
      color: white;
      font-size: .8rem;
      }
      
      .ProductsPage .featuredSection #owl-carousel .itemContainer .short_decription{
          font-weight: bold;
          font-size: .8rem;
          color: gray;
      }
      /* .ProductsPage .featuredSection #owl-carousel .itemContainer .new_price{
      color: var(--primary);
      font-weight: bold;
      font-size: .9rem;
      }
      .ProductsPage .featuredSection #owl-carousel .itemContainer .old_price{
          text-decoration: line-through;
          text-decoration-color: rgb(255, 135, 87);
          color:  rgb(255, 135, 87);
          font-weight: bold;
      font-size: .9rem;
      } */
      
}