/* eslint-disable  */
.ApplyLoans .loanDivision{
   display: flex;
   position: relative;
   justify-content: space-between;
}

.ApplyLoans .signatureContainer{
  height: 100px;
  width: 500px;
  border: 6px solid green;
}

 .loan_chip{
 display: flex;
 justify-content: center;
 align-items: center;
}

.ApplyLoans .chip_container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px;
  
}
.ApplyLoans .chip_container .chip_card{
  position: relative;
  width: auto;
  height: 30px;
  display: flex;
  
justify-content: center;
align-items: center;
color: white;
  /* margin: 30px; */
  /* background: #287bff; */
  background: gray;
  border-radius: 10px;
  border-top-right-radius: 160px;
  border-bottom-right-radius: 160px;
  box-shadow: 8px 0px 0 #bdbdbd6e,
  inset -8px 0 0 rgba(255, 255, 255, 0.25), 
  8px 0 0 rgba(0, 0, 0, 0.15);

}
.ApplyLoans .chip_container .chip_card::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* height: ; */
  

}
.ApplyLoans .chip_container .chip_card .chip_content{
  padding: 0rem 10px; 
}

.ApplyLoans .form-chips{
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 50px;
}
.ApplyLoans .chip{
  border-radius: 4pc;

}
.ApplyLoans .loanDivision .northloan, .ApplyLoans .loanDivision .southloan{
   width: 300px;
   border: .5px solid rgba(128, 128, 128, 0.527);
   padding: 1.5rem 1rem;
}
.ApplyLoans .loanDivision .northloan .title, .ApplyLoans .loanDivision .southloan .title{
  font-size: 1.8rem;
  margin-bottom: .5rem;

}
.ApplyLoans .loanDivision .northloan .help, .ApplyLoans .loanDivision .southloan .help{
  font-size: .8rem;
  color: var(--secondaryColor);
  font-weight: 500;
}

.ApplyLoans .loanDivision .northloan .check-icon{
    /* position: absolute;
    right: 0; */
    width: 60px;
    position: absolute;
    top: 5px;
    right: 800px;
}
.ApplyLoans .loanDivision .southloan .check-icon{
    /* position: absolute;
    right: 0; */
    width: 60px;
    position: absolute;
    top: 5px;
    /* right: 00px; */
}

.ApplyLoans .Loan_Banner_Area{
  height: 500px;
}
.ApplyLoans .Loan_Banner_Area .overlay{
  height: 100%;
  background-color: rgba(255, 0, 0, 0.514);
}

.ApplyLoans  form label{
  margin: 1rem 0rem .3rem 0rem;
  font-weight: 500;
  font-size: .9rem;
}
.ApplyLoans  form .bto-lb-required{
color: red;
}

.ApplyLoans .formTitle{
  margin: 0;
  font-size: 1.4rem;
  padding-top: 1rem;
  color: rgb(92, 92, 92);
  
}
.ApplyLoans .radioContainer{
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}
.ApplyLoans .radioContainer label{
font-size: 1rem;
  margin-right: .5rem;
}
.ApplyLoans .form4 .image-card{
  height: 240px;
  border: 2px dotted gray;
  width: 50%;
  margin: 0 auto;
  margin-bottom: .5rem;
  border-radius: .5vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ApplyLoans .form4 .image-card img{
  height: 230px;
  width: auto;
  margin: 0 auto;

  border-radius: .5vw;
}

.ApplyLoans .card_container{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ApplyLoans .form4 .preview_image-card img{
  height: 230px;
  /* width: auto;
  margin: 0 auto; */

  border-radius: .5vw;
}

.ApplyLoans .preview_card_container{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.PreviewName{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
}
.value{
  /* background: gray; */
  font-weight: bold;
}

.loanTerms p{
  padding: 1rem 0rem;
}

.loanTerms .title{
  text-align: center;
  font-weight: bold;
  padding-top: 1rem;
}
.ApplyLoans input, .ApplyLoans select,.ApplyLoans textarea{
  border: none !important;
  margin-bottom: .4rem;
}
@media screen and (max-width: 500px) {
  .form4 .card_container {
    display: block;
    justify-content: center;
  }
  .ApplyLoans .loan_chip {
    display: grid;
  grid-template-columns: repeat(4, 1fr);
  }
  .ApplyLoans .chip_container .chip_card .chip_content{
    padding: 0rem 10px;
    font-size: .7rem; 
  }
  .form4 .labelAndInput{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem 0rem;
  }
  .form4 .labelAndInput input{
    width: 60%;
    background: lightblue;
    border: none;
    border-radius: 4pc;
    padding: .4rem .7rem;
  }
  .form4 label{
    font-weight: bold !important;

  }
  .form4 .formTitle{
    
    text-align: center;
    font-weight: bold;
  }
  .form4 .description{
   padding: 1rem 0rem;
   width: 90%;
   text-align: center;
   margin: 0 auto;

  }
  .form4 .card_container img{
    width: 100% !important;
  }
  .form4 .image-card{
    width: 100% !important;

  }
}