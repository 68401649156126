/* eslint-disable  */
.ProfilePage .photos {
  position: relative;
}
.ProfilePage .photos .coverPhoto {
  height: 230px;
  width: 100%;
  border-radius: 2rem 0rem 0rem 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.ProfilePage .photos .coverPhoto img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.ProfilePage .photos .iconUploadButton {
  background: rgba(255, 255, 255, 0.658);
  border-radius: 50%;
  position: absolute;
}
.ProfilePage .photos .profileImage {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  right: 0;
  left: 0;
  top: -24px;
}
.ProfilePage .photos .profileImage .profile {
  display: flex;
  align-items: center;
}
.ProfilePage .photos .profileImage .profile .profileImgCover {
  border: 0.3rem solid #f4f4f4;
  background: white;
  height: 150px;
  width: 150px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.ProfilePage .photos .profileImage .profile .profileImgCover img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.ProfilePage .photos .profileImage .profile_btn {
  display: flex;
}
.ProfilePage .photos .profileImage .profile_btn .cancelBtn {
  margin-right: 1rem;
}
.ProfilePage .photos .iconUploadButton {
  background: rgba(255, 255, 255, 0.658);
  border-radius: 50%;
}
.ProfilePage .photos .profileImage {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  right: 0;
  left: 0;
  top: -24px;
}
.ProfilePage .photos .profileImage .profile {
  display: flex;
  align-items: center;
}
.ProfilePage .photos .profileImage .profile .pCover {
  background: white;
  height: 150px;
  width: 150px;
  border-radius: 50%;
}
.ProfilePage .photos .profileImage .profile .profileImgCover {
  border: 0.3rem solid #f4f4f4;
  background: white;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.ProfilePage .photos .profileImage .profile .profileImgCover img {
  width: 150px;
  height: 150px;
}
.ProfilePage .photos .profileImage .profile_btn {
  display: flex;
}
.ProfilePage .photos .profileImage .profile_btn .cancelBtn {
  margin-right: 1rem;
}
.ProfilePage .profileDetails {
  width: 75%;
  margin: 0 auto;
}
.ProfilePage .profileDetails .gridBox {
  display: grid;
  grid-template-columns: 30% 70%;
  align-items: center;
  margin-bottom: 1rem;
}
.ProfilePage .profileDetails .username {
  flex-grow: 1;
}

.NotificationsPage .notifications {
  width: 70%;
  margin: 0 auto;
  padding: 0.3rem;
}
.NotificationsPage .notifications h3 {
  font-size: 1.3rem;
}
.NotificationsPage .notifications .flex {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  background: var(--whiteColor);
  margin-bottom: 1rem;
  border-radius: 0.7vw;
}
.NotificationsPage .notifications .flex .icon {
  margin-right: 0.4rem;
}
.NotificationsPage .notifications .flex .notif_title {
  font-size: 0.9rem;
  margin: 0;
  font-weight: 500;
}
.NotificationsPage .notifications .flex .notif_msg {
  font-size: 0.7rem;
  padding: 0.1rem 0rem;
}
.NotificationsPage .notifications .flex .time {
  font-size: 0.6rem;
  color: var(--grayColor);
}

.LoansPage .searchAndGridList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
.LoansPage .searchAndGridList .searchBar input {
  outline: none;
  border: none;
  padding: 0.5rem;
  width: 400px;
  border-radius: 4pc;
  padding-right: 2.5rem;
}
.LoansPage .searchAndGridList .searchBar button {
  border: none;
  background: var(--primaryColor);
  padding: 0.5rem;
  border-radius: 4pc;
  width: 100px;
  position: relative;
  right: 30px;
}

.ConfirmEmailPassword {
  background: url("/public/images/dev/nurse-float.jpeg") no-repeat center center/cover;
  height: 100vh;
  width: 100%;
}
.ConfirmEmailPassword .overlay {
  background: rgba(255, 255, 255, 0.877);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ConfirmEmailPassword .overlay form {
  background: rgba(255, 255, 255, 0.781);
  width: 37%;
  padding: 2rem 1rem;
}
.ConfirmEmailPassword .overlay form .title {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 1rem;
  font-weight: 400;
  color: rgb(80, 80, 80);
}
.ConfirmEmailPassword .overlay .inputGroup {
  position: relative;
}
.ConfirmEmailPassword .overlay .inputGroup .icon {
  position: absolute;
  right: 10px;
  top: 8px;
  color: rgb(175, 175, 175);
  cursor: pointer;
}
.ConfirmEmailPassword .overlay input {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 0.8rem;
}
.ConfirmEmailPassword .profile_email_dob_container {
  display: grid;
  grid-template-columns: 60% 40%;
  grid-column-gap: 10px;
}

.MyOrdersNdPurchases {
  background-color: white !important;
}
.MyOrdersNdPurchases .item {
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-shadow: 1px 5px 15px rgba(110, 110, 110, 0.5);
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.MyOrdersNdPurchases .item .item1 {
  width: 90%;
}
.MyOrdersNdPurchases .item .item1 .title {
  font-size: 1rem;
}
.MyOrdersNdPurchases .item .item1 .value {
  font-weight: bold;
}
.MyOrdersNdPurchases .summary .flex {
  margin-bottom: 0.7rem;
  width: 30%;
  justify-content: space-between;
}
.MyOrdersNdPurchases .status {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 1rem;
}
.MyOrdersNdPurchases .status .card {
  padding: 0.4rem;
}
.MyOrdersNdPurchases .status .card .status_tracker {
  border: 5px solid rgba(197, 197, 197, 0.842);
  border-radius: 4pc;
  margin: 1rem 0rem 0.2rem 0rem;
}
.MyOrdersNdPurchases .status .readMessageContainer {
  position: absolute;
  right: 0;
}
.MyOrdersNdPurchases .status .readMsg {
  position: absolute;
  right: 0;
  bottom: 25px;
  font-size: 0.7rem;
  color: red;
}
.MyOrdersNdPurchases .status .messageIcon {
  color: red;
  font-size: 3rem;
}
.MyOrdersNdPurchases .MyOrders {
  margin-top: 2rem;
}
.MyOrdersNdPurchases .MyOrders .order {
  display: flex;
}
.MyOrdersNdPurchases .MyOrders .order img {
  width: 200px;
}
.MyOrdersNdPurchases .MyOrders .order .info {
  margin-left: 1rem;
}

@media screen and (max-width: 500px) {
  .NotificationsPage .notifications {
    width: 100%;
  }
  .NotificationsPage .notifications .flex {
    margin-bottom: 0.7rem;
    border-radius: 1vw !important;
  }
  .ProfilePage .photos {
    position: relative;
  }
  .ProfilePage .photos .coverPhoto {
    height: 200px;
    width: 100%;
    max-width: 100%;
    max-height: 230px;
    border-radius: 2rem 0rem 0rem 0rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ProfilePage .photos .iconUploadButton {
    background: rgba(255, 255, 255, 0.658);
    border-radius: 50%;
  }
  .ProfilePage .photos .profileImage {
    display: block;
    justify-content: space-between;
    align-items: center;
    position: relative;
    right: 0;
    left: 0;
    top: -4px;
  }
  .ProfilePage .photos .profileImage .profile {
    display: block;
    align-items: center;
  }
  .ProfilePage .photos .profileImage .profile .pCover {
    background: transparent;
    height: 100px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 50%;
  }
  .ProfilePage .photos .profileImage .profile .pCover .iconUploadButton {
    background: transparent;
  }
  .ProfilePage .photos .profileImage .profile .profileImgCover {
    border: 0.3rem solid #f4f4f4;
    background: white;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  .ProfilePage .photos .profileImage .profile .profileImgCover img {
    width: 100px;
    height: 100px;
  }
  .ProfilePage .photos .profileImage .profile_btn {
    display: flex;
    justify-content: right;
  }
  .ProfilePage .photos .profileImage .profile_btn .cancelBtn {
    margin-right: 0.6rem;
  }
  .ProfilePage .profileDetails {
    margin-top: 1rem !important;
    width: 100%;
    margin: 0 auto;
  }
  .ProfilePage .profileDetails .gridBox {
    display: grid;
    grid-template-columns: 30% 70%;
    align-items: center;
    margin-bottom: 1rem;
  }
  .ProfilePage .profileDetails .username {
    flex-grow: 1;
  }
  .LoansPage .searchAndGridList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  .LoansPage .searchAndGridList .searchBar input {
    outline: none;
    border: none;
    padding: 0.5rem;
    width: auto;
    border-radius: 4pc;
    padding-right: 2.5rem;
  }
  .LoansPage .searchAndGridList .searchBar button {
    border: none;
    background: var(--primaryColor);
    padding: 0.5rem;
    border-radius: 4pc;
    width: 100px;
    position: relative;
    right: 30px;
  }
  .LoansPage .duesTableContainer {
    overflow: hidden;
    max-width: 100%;
    width: 100%;
    overflow-x: scroll;
  }
  .ConfirmEmailPassword {
    background: url("/public/images/dev/nurse-float.jpeg") no-repeat center center/cover;
    height: 100vh;
    width: 100%;
  }
  .ConfirmEmailPassword .overlay {
    background: rgba(255, 255, 255, 0.74);
  }
  .ConfirmEmailPassword .overlay form {
    background: rgba(255, 255, 255, 0.781);
    width: 90%;
    padding: 2rem 1rem;
  }
  .ConfirmEmailPassword .overlay form .title {
    width: 90%;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 1rem;
    font-weight: 400;
    color: rgb(80, 80, 80);
  }
  .ConfirmEmailPassword .overlay .inputGroup {
    position: relative;
  }
  .ConfirmEmailPassword .overlay .inputGroup .icon {
    position: absolute;
    right: 10px;
    top: 8px;
    color: rgb(175, 175, 175);
    cursor: pointer;
  }
  .ConfirmEmailPassword .overlay input {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 0.8rem;
  }
  .ConfirmEmailPassword .profile_email_dob_container {
    display: block;
    margin-bottom: 1rem;
  }
  .MyOrdersNdPurchases {
    background-color: white !important;
  }
  .MyOrdersNdPurchases .item {
    padding: 5px;
    display: block;
    align-items: center;
    justify-content: space-around;
    box-shadow: 1px 5px 15px rgba(110, 110, 110, 0.5);
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  .MyOrdersNdPurchases .item .item1 {
    width: 95%;
  }
  .MyOrdersNdPurchases .item .item1 .title {
    font-size: 0.8rem;
  }
  .MyOrdersNdPurchases .item .item1 .value {
    font-weight: bold;
    font-size: 0.8rem;
  }
  .MyOrdersNdPurchases .summary .flex {
    margin-bottom: 0.2rem;
    width: auto;
    justify-content: space-between;
  }
  .MyOrdersNdPurchases .status {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 0.5rem;
  }
  .MyOrdersNdPurchases .status .card {
    padding: 0.2rem;
  }
  .MyOrdersNdPurchases .status .card .status_tracker {
    border: 5px solid rgba(197, 197, 197, 0.842);
    border-radius: 4pc;
    margin: 1rem 0rem 0.2rem 0rem;
  }
  .MyOrdersNdPurchases .status .card .status_name,
  .MyOrdersNdPurchases .status .card .status_date {
    font-size: 0.6rem;
  }
  .MyOrdersNdPurchases .status .readMessageContainer {
    position: absolute;
    bottom: 20px;
  }
  .MyOrdersNdPurchases .status .readMsg {
    position: absolute;
    left: 0;
    bottom: 20px;
    font-size: 0.5rem;
    color: red;
  }
  .MyOrdersNdPurchases .status .messageIcon {
    color: red;
    font-size: 1.5rem;
  }
  .MyOrdersNdPurchases .deleteBtn {
    display: flex;
    justify-content: flex-end;
  }
  .MyOrdersNdPurchases .MyOrders {
    margin-top: 2rem;
  }
  .MyOrdersNdPurchases .MyOrders .order {
    display: flex;
  }
  .MyOrdersNdPurchases .MyOrders .order img {
    width: 200px;
  }
  .MyOrdersNdPurchases .MyOrders .order .info {
    margin-left: 1rem;
  }
}/*# sourceMappingURL=Pages.css.map */