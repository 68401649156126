/* eslint-disable  */


@media screen and (max-width: 500px){
    .messageTop{
        display: flex;
        align-items: center;
    }
    .messageText{
        padding: 3px 10px;
        background: var(--primary);
        color: white;
        border-radius: 20px;
        max-width: 500px;
        font-size: .8rem;
      
    }
    .messageBottom{
        font-size: .6rem;
        max-width: 500px;
        margin-left: 3rem;
        padding-top: 0rem;
        color: rgb(207, 207, 207);
        /* text-align: end; */
    }
}
