/* eslint-disable  */
.loginPage {
  display: grid;
  grid-template-columns: 25% 75%;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.loginPage .section_one .overlay {
  padding: 1rem;
  background: rgba(255, 255, 255, 0.884);
  height: 100vh;
  display: flex;
  justify-items: center;
  flex-direction: column;
  justify-content: center;
}
.loginPage .section_one .overlay .title {
  padding-bottom: 2rem;
  color: var(--primaryColor);
}
.loginPage .email-form-group {
  margin-bottom: 1rem;
}
.loginPage .password-form-group {
  margin-bottom: 1rem;
}
.loginPage .forgot_password {
  text-align: end;
}
.loginPage .trigger_register {
  padding-top: 2rem;
  text-align: center;
}
.loginPage .trigger_register a {
  text-decoration: none;
}
.loginPage .section_two {
  border-radius: 0rem 0rem 0rem 3rem;
}
.loginPage .section_two .overlay {
  background: rgba(0, 89, 255, 0.692);
  height: 100vh;
  display: flex;
  justify-items: center;
  flex-direction: column;
  justify-content: center;
  color: white;
  border-radius: 0rem 0rem 0rem 3rem;
}
.loginPage .section_two .overlay .content {
  padding: 2rem;
}
.loginPage .section_two .overlay .content h1 {
  font-size: 4rem;
  font-weight: bold;
  width: 80%;
  padding-bottom: 2rem;
}
.loginPage .section_two .overlay .content p {
  width: 50%;
}

.loginPage  .inputGroup {
  position: relative;
}
.loginPage  .inputGroup .icon {
  position: absolute;
  right: 10px;
  top: 12px;
  color: rgb(175, 175, 175);
  cursor: pointer;
}

.videoContainer{
  margin-top: 3rem;

}
.videoContainer h2{
  font-size: 2rem;

}
.videoContainer h3{
  font-size: 1.3rem;

}
.videoContainer img{
  width: 90px;
  cursor: pointer;
}
/* video {
	margin: 30px;
	width: 500px;
	max-width: 85%;
  position: absolute;
  bottom: 0;
  right: 0;
} */

/* REGISTER PAGE */

.registerPage {
  display: grid;
  grid-template-columns: 25% 75%;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.registerPage .section_one .overlay {
  padding: 1rem;
  background: rgba(255, 255, 255, 0.884);
  height: 100vh;
  display: flex;
  justify-items: center;
  flex-direction: column;
  justify-content: center;
}
.registerPage .section_one .overlay .title {
  padding-bottom: 2rem;
  color: var(--primaryColor);
}
.registerPage .email-form-group {
  margin-bottom: 1rem;
}
.registerPage .password-form-group {
  margin-bottom: 1rem;
}
.registerPage .forgot_password {
  text-align: end;
}
.registerPage .trigger_register {
  padding-top: 2rem;
  text-align: center;
}
.registerPage .trigger_register a {
  text-decoration: none;
}
.registerPage .section_two {
  border-radius: 0rem 0rem 0rem 3rem;
}
.registerPage .section_two .overlay {
  background: rgba(0, 89, 255, 0.692);
  height: 100vh;
  display: flex;
  justify-items: center;
  flex-direction: column;
  justify-content: center;
  color: white;
  border-radius: 0rem 0rem 0rem 3rem;
}
.registerPage .section_two .overlay .content {
  padding: 2rem;
}
.registerPage .section_two .overlay .content h1 {
  font-size: 4rem;
  font-weight: bold;
  width: 80%;
  padding-bottom: 2rem;
}
.registerPage .section_two .overlay .content p {
  width: 50%;
}

.ResetPassword {
  height: 100vh;
  background: url("/public/images/dev/nurse-float.jpeg") no-repeat center center/cover;
}
.ResetPassword .overlay {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.863);
}
.ResetPassword .overlay .form {
  width: 40%;
}
.ResetPassword .overlay .form input {
  margin-bottom: 1rem;
}



@media screen and (max-width: 500px) {
  .loginPage {
    display: block;
    grid-template-columns: 25% 75%;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  .loginPage .section_one {
    height: 70vh;
  }
  .loginPage .section_one .overlay {
    padding: 1rem;
    background: rgba(255, 255, 255, 0.884);
    height: 70vh;
    display: flex;
    justify-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .loginPage .section_one .overlay .title {
    padding-bottom: 1rem;
    color: var(--primaryColor);
    font-size: 2rem;
  }
  .loginPage .email-form-group {
    margin-bottom: 1rem;
  }
  .loginPage .password-form-group {
    margin-bottom: 1rem;
  }
  .loginPage .forgot_password {
    text-align: end;
  }
  .loginPage .trigger_register {
    padding-top: 1rem;
    text-align: center;
  }
  .loginPage .trigger_register a {
    text-decoration: none;
  }
  .loginPage .section_two {
    border-radius: 3rem 0rem 0rem 0rem;
    height: 30vh;
  }
  .loginPage .section_two .overlay {
    background: rgba(1, 110, 101, 0.692);
    height: 30vh;
    display: flex;
    justify-items: center;
    flex-direction: column;
    justify-content: center;
    color: white;
    border-radius: 3rem 0rem 0rem 0rem;
  }
  .loginPage .section_two .overlay .content h1 {
    font-size: 1.5rem;
    font-weight: bold;
    width: 100%;
    padding-bottom: 1rem;
  }
  .loginPage .section_two .overlay .content p {
    width: 100%;
  }
  .ResetPassword .overlay {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.863);
  }
  .ResetPassword .overlay .form {
    width: 80%;
  }
  .ResetPassword .overlay .form input {
    margin-bottom: 1rem;
  }

  .videoContainer{
    position: absolute;
    top:450px;
    
  }
  .videoContainer img{
    width: 50px;
    margin-top: 5px;
  }
  .videoContainer h2, .videoContainer h3{
    color: black;
    font-size: 1rem;
    font-weight: bold;
  }
}/*# sourceMappingURL=Auth.css.map */