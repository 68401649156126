/* eslint-disable  */
.Conversation{
    display: flex;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    cursor: pointer;
    align-items: center;
    margin-top: 5px;
    justify-content: space-between;
}
.Conversation .userProfile{
    display: flex;
    align-items: center;
  
}
.Conversation .title{
    font-weight: 600;
}
.Conversation .username{
    color: gray;
}
.Conversation:hover{
    background: #01a94a2a;
}
.Conversation.active{
    background: #01a94a2a;
}

.conversationImage{
    width: 50px;
    height:50px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}

@media screen and (max-width: 500px) {
    /* .Conversation{
        display: block;
        text-align: center;

    }
    .conversationImage{
        margin: 0 auto;
    }
    .username{
        font-size: .8rem;
        padding: 0;
        margin: 0;
    } */
}