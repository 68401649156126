.productImage {
   cursor: pointer;

}
.productImage .img{
    width: 60%;
    margin: 0 auto;

}

@media screen and (max-width: 500px) {
    .productImage .img{
        width: 90%;
        margin: 0 auto;
    
    }
}
