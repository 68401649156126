.MessagesPage .listNdGridIcons {
  display: flex;
  justify-content: space-between;
}

.MessagesPage .searchAndGridList {
  display: flex;
  margin: 1rem 0rem;
  justify-content: space-between;
  align-items: center;
}
.MessagesPage .searchCover {
  width: 70%;
}

.MessagesPage .listNdGridIcons .icon {
  margin: 0rem 1.5rem;
  font-size: 2rem;
}
.MessagesPage .searchCover input {
  outline: none;
  width: 100%;
  border: none;
  padding: 0.5rem 0.5rem;
  border-radius: 4pc;
}



@media screen and (max-width: 500px) {
  .MessagesPage .fundsTableContainer .header {
    font-size: 0.7rem;
  }
  .MessagesPage .fundsTableContainer .data {
    font-size: 0.7rem;
  }

  .searchCover {
    /* width: 100% !important; */
  }

  .MessagesPage .searchAndGridList {
    display: grid;
    grid-template-columns: 50% 50%;
    margin: 1rem 0rem;
    justify-content: space-between;
    align-items: center;
  }
  .MessagesPage .searchCover {
    width: 80%;
  }
  .MessagesPage .listNdGridIcons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .MessagesPage .listNdGridIcons .icon {
    /* width: 30%; */
    margin: 0rem 0.8rem;
    font-size: 1.3rem;
  }

  .MessagesPage .listNdGridIcons .button {
    font-size: 0.7rem;
  }
  .MessagesPage .searchCover input {
    padding: 0.3rem 0.5rem;
    font-size: 0.9rem;
    /* width: 130px; */
    font-size: 0.7rem !important;
  }

  .MessagesPage .messageTable{
    overflow-x: scroll;
  }
  .MessagesPage table{
    font-size: .8rem;
    width: 500px;
    overflow-x: scroll;
  }
  .MessagesPage .messageTable th{
    font-size: .7rem;
  }
  .MessagesPage .messageTable td{
    font-size: .6rem;
  }
}

/* /////  THE MESSAGE ADMIN STYLING SGHIOULD BE DHERE. */
.MessageDetailed {
  overflow: hidden !important;
  position: relative;
  height: 90vh;
}
.MessageDetailed .messageShowCase {
  background: white;
  /* padding: 1rem; */
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  box-shadow: var(--shadow);
}
.MessageDetailed .messageShowCase h2 {
  background: var(--primary);
  padding: 10px;
  color: white;
  font-weight: bold;
  font-size: 1.1rem;
}
.MessageDetailed .messageShowCase .itemsConainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}
.MessageDetailed .messageShowCase .itemsConainer .icon {
  font-size: 1.5rem;
}

.MessageDetailed .messageBox {
  height: 60vh;
  overflow: hidden;
  overflow-y: scroll;
  /* padding-top: 100px; */
}


/* === CONVERSATIONS === */

.online {
  position: relative;
  top: 30px;
  left: 35px;
  width: 13px;
  height: 13px;
  background-color: #8bc34a;
  border-radius: 13px;
  border: 3px solid #fafafa;
}

.MessageDetailed .chat {
  /* width: calc(65% - 85px); */
  width: 100%;
  position: relative;
  overflow-y: scroll;

  /* height: calc(80vh - 100px); */
}

.MessageDetailed .header-chat {
  background-color: #fff;
  height: 90px;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
}

.chat .header-chat .icon {
  margin-left: 30px;
  color: #515151;
  font-size: 14pt;
}

.chat .header-chat .name {
  margin: 0 0 0 20px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-size: 13pt;
  color: #515151;
}

.chat .header-chat .right {
  position: absolute;
  right: 40px;
}

.chat .messages-chat {
  padding: 25px 35px;
  padding-bottom: 80px;
  height: 100%;
  /* background-color: red; */
  overflow-y: scroll;
}

.chat .messages-chat .message {
  display: flex;
  /* align-items: flex-start; */
  flex-direction: row;
  margin-bottom: 8px;
}

.chat .messages-chat .message .photo {
  display: block;
  width: 45px;
  height: 45px;
  background: #e6e7ed;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.chat .messages-chat .text {
  margin: 0 35px;
  background-color: #f6f6f6;
  padding: 15px;
  border-radius: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.MessageDetailed .text-only {
  margin-left: 45px;
}

.MessageDetailed .time {
  font-size: 10px;
  color: rgb(153, 153, 153);
  margin-bottom: 10px;
  margin-left: 85px;
}

.MessageDetailed .response-time {
  float: right;
  font-size: 0.7rem;
  color: rgb(153, 153, 153);
  margin-right: 40px !important;
}

.MessageDetailed .response {
  float: right;
  margin-right: 0px !important;
  margin-left: auto; /* flexbox alignment rule */
}

.MessageDetailed .response .text {
  background-color: #e3effd !important;
}

.footer-chat {
}

.MessageDetailed form {
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;

  position: absolute;
  bottom: 0;
  /* top: 355px; */
  right: 0;
  left: 0;
  background-color: transparent;
  border-top: 2px solid #eee;
  overflow: hidden;
  align-items: center;
}
.MessageDetailed form .button {
  background: var(--primary);
}
.chat .footer-chat .icon {
  /* margin-left: 30px; */
  color: #c0c0c0;
  font-size: 14pt;
}

.chat .footer-chat .send {
  color: #fff;
  background-color: #4f6ebd;
  position: absolute;
  right: 50px;
  padding: 12px 12px 12px 12px;
  border-radius: 50px;
  font-size: 14pt;
}

.chat .footer-chat .name {
  margin: 0 0 0 20px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-size: 13pt;
  color: #515151;
}

.chat .footer-chat .right {
  position: absolute;
  right: 40px;
}

.write-message {
  border: none !important;
  width: 80%;
  flex: 1;
  height: 50px;
  /* margin-left: 20px; */
  padding: 10px;
}

.footer-chat *::-webkit-input-placeholder {
  color: #c0c0c0;
  font-size: 13pt;
}
.footer-chat input *:-moz-placeholder {
  color: #c0c0c0;
  font-size: 13pt;
}
.footer-chat input *::-moz-placeholder {
  color: #c0c0c0;
  font-size: 13pt;
  margin-left: 5px;
}
.footer-chat input *:-ms-input-placeholder {
  color: #c0c0c0;
  font-size: 13pt;
}

.clickable {
  cursor: pointer;
}

@media screen and (max-width: 500px) {
  .MessageDetailed .messageShowCase {
    background: white;
    /* padding: 1rem; */
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    box-shadow: var(--shadow);
  }
  .MessageDetailed .messageShowCase h2 {
    background: var(--primary);
    padding: 10px;
    color: white;
    font-weight: bold;
    font-size: 0.8rem;
  }
  .MessageDetailed .messageShowCase .itemsConainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
  }
  .MessageDetailed .messageShowCase .itemsConainer .item {
    font-size: 0.6rem;
  }
  .MessageDetailed .messageShowCase .itemsConainer .icon {
    font-size: 0.8rem;
  }

  .MessageDetailed .messageBox {
    height: 60vh;
    overflow: hidden;
  }

  .chat {
    /* width: calc(65% - 85px); */
    width: 100%;
    position: relative;
    height: calc(80vh - 100px);
  }

  .header-chat {
    background-color: #fff;
    height: 90px;
    box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
  }

  .chat .header-chat .icon {
    margin-left: 30px;
    color: #515151;
    font-size: 14pt;
  }

  .chat .header-chat .name {
    margin: 0 0 0 20px;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    font-size: 13pt;
    color: #515151;
  }

  .chat .header-chat .right {
    position: absolute;
    right: 40px;
  }

  .chat .messages-chat {
    padding: 25px 10px;
    padding-bottom: 80px;
    height: 100%;
    /* background-color: red; */
    overflow-y: scroll;
  }

  .chat .messages-chat .message {
    display: flex;
    /* align-items: flex-start; */
    flex-direction: row;
    margin-bottom: 8px;
  }

  .chat .messages-chat .message .photo {
    display: block;
    width: 35px;
    height: 35px;
    background: #e6e7ed;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .chat .messages-chat .text {
    margin: 0 5px;
    background-color: #f6f6f6;
    padding: 5px;
    font-size: 0.8rem;
    border-radius: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .text-only {
    margin-left: 45px;
    margin-bottom: 1.3rem !important;
  }

  .MessageDetailed .time {
    font-size: 10px;
    color: lightgrey;
    margin-bottom: 10px;
    margin-left: 45px;
  }

  .MessageDetailed .response-time {
    float: right;
    margin-right: 40px !important;
    font-size: 0.5rem;
    position: relative;
    bottom: -20px;
    color: rgb(172, 172, 172);
  }

  .MessageDetailed .response {
    float: right;
    margin-right: 0px !important;
    margin-left: auto; /* flexbox alignment rule */
  }

  .MessageDetailed .response .text {
    background-color: #e3effd !important;
  }

  .footer-chat {
  }

  .MessageDetailed form {
    display: flex;
    align-items: center;
    width: 100%;
    height: 80px;

    position: absolute;
    bottom: 0;
    /* top: 355px; */
    right: 0;
    left: 0;
    background-color: transparent;
    border-top: 2px solid #eee;
    overflow: hidden;
    align-items: center;
  }
  .MessageDetailed form .button {
    background: var(--primary);
  }
  .chat .footer-chat .icon {
    /* margin-left: 30px; */
    color: #c0c0c0;
    font-size: 14pt;
  }

  .chat .footer-chat .name {
    margin: 0 0 0 20px;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    font-size: 13pt;
    color: #515151;
  }

  .chat .footer-chat .right {
    position: absolute;
    right: 40px;
  }

  .write-message {
    border: none !important;
    width: 80%;
    flex: 1;
    height: 40px;
    /* margin-left: 20px; */
    padding: 10px;
  }

  .footer-chat *::-webkit-input-placeholder {
    color: #c0c0c0;
    font-size: 13pt;
  }
  .footer-chat input *:-moz-placeholder {
    color: #c0c0c0;
    font-size: 13pt;
  }
  .footer-chat input *::-moz-placeholder {
    color: #c0c0c0;
    font-size: 13pt;
    margin-left: 5px;
  }
  .footer-chat input *:-ms-input-placeholder {
    color: #c0c0c0;
    font-size: 13pt;
  }

  .clickable {
    cursor: pointer;
  }
}
