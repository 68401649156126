
.terms_container{
    width:  80%;
    margin:  0 auto;
    background: white;
    padding: 20px;
}
.terms_container .title{
    text-align: center;
    font-size: 1.8rem;
}
.terms_container p{
    margin: 15px 0px;
}

@media screen and (max-width: 500px) {
    .terms_container{
        width:  100%;
        margin:  0 auto;
        background: white;
        padding: 5px;
    }
    .terms_container .title{
        text-align: center;
        font-size: 1.3rem;
    }
}