/* eslint-disable  */
.Faq .faqTop {
  background: rgb(56, 122, 114);
  height: 200px;
  padding: 10px;
  border-radius: 10px;
  color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.Faq .faqTop .items {
  width: 90%;
  margin: 0 auto;
}

.Faq .faqTop .items h1 {
  font-size: 1.7rem;
  padding: 0.5rem 0rem;
}
.Faq .faqTop .items p {
  font-size: 0.8rem;
  color: rgb(223, 223, 223);
}

.Faq .faqBottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 4rem;

}
.Faq .faqBottom .item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: ce; */
  padding-bottom: 1rem;
}
.Faq .faqBottom .section1 {
padding-right: 2rem;
}
.Faq .faqBottom .section1 .title{
font-weight: bold;
font-size: 1.5rem;
}
.Faq .faqBottom .item .faqDetails {
  max-width: 97%;
}
.Faq .faqBottom .item .faqDetails h3{
  font-weight: bold;
}
.Faq .faqBottom .item .faqDetails p{
  color: rgb(139, 139, 139);
  padding-top: .4rem;
}

.Faq .faqBottom .details{
    /* display: none; */
    visibility: hidden;
    height: 0px;
}
.Faq .faqBottom .details.active{
    /* display: block; */
    visibility: visible;
    height: auto;
}

@media screen and (max-width: 500px) {
    .Faq{
        background-color: white !important;
    }
    .Faq .faqBottom {
        display: block;
        padding-top: 4rem;
      
      }
      .Faq .faqBottom .item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        /* align-items: ce; */
        padding-bottom: 1rem;
      }
      .Faq .faqBottom .section1 {
      margin-bottom: 1rem;
      }
      .Faq .faqBottom .section1 .title{
      font-weight: bold;
      font-size: 1.5rem;
      }
      .Faq .faqBottom .item .faqDetails {
        max-width: 97%;
      }
      .Faq .faqBottom .item .faqDetails h3{
        font-weight: bold;
      }
      .Faq .faqBottom .item .faqDetails p{
        color: rgb(139, 139, 139);
        padding-top: .4rem;
      }
      
}